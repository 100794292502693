import React, { memo } from "react";
import "../styles/ComponentStyles/Footer.css";
import { homePageSocialIcons } from "../data/homePageData";
import Icons from "./Icons";
import useResize from "../hooks/useResize";
import { deviceLayout } from "../utilites/Constant";



const Footer = ({ color = "var(--bg-white)" }) => {
  const { screenSize } = useResize();
  return (
    <div className="footer-container">
      <span
        style={{
          backgroundColor: color,
        }}
        className="footer-span center-column"
      ></span>
      <div className="footer-content">
        <p
          style={{
            color: color,
            marginBottom:   screenSize.width <= deviceLayout.responsivePreview.Mobile[0] ? "3rem" : ""
          }}
          className="font-normal-brooklyn"
        >
          copyright@amitsamui2023
        </p>
        <Icons iconColor={color} iconData={homePageSocialIcons} />
        <p
          style={{
            color: color,
            marginTop:   screenSize.width <= deviceLayout.responsivePreview.Mobile[0] ? "2rem" : ""

          }}
          className="font-normal-brooklyn"
        >
          amitsamui257@gmail.com
        </p>
      </div>
    </div>
  );
};

export default memo(Footer);
