import React, { memo } from "react";
import "../styles/ComponentStyles/ProminentWorks.css";
import SingleWork from "./SingleWork";

const ProminentWorks = ({
  // backgroundColor = "var(--bg-dark)",
  // color = "var(--bg-white)",
  prominentWorks,
}) => {

  

  return (
    <div className="works-container center">
      <div className="works-container-left works-container-pane center-column">
        {
          prominentWorks && prominentWorks.worksLeft.map((work , index) => {

            return (
              <SingleWork
              textColor={work.styles.textColor}
              imageSource={work.imageSource}
              link={work.linkTo}
              description={work.description}
              title={work.title}
            />
            )
          })
        }
       
      </div>
      <div className="works-container-right works-container-pane center-column">
      {
          prominentWorks && prominentWorks.worksRight.map((work , index) => {
            return (
              <SingleWork
              textColor={work.styles.textColor}
              imageSource={work.imageSource}
              link={work.linkTo}
              description={work.description}
              title={work.title}
            />
            )
          })
        }
      </div>
    </div>
  );
};

export default memo(ProminentWorks);
