import React, { memo } from "react";
import { useParams } from "react-router-dom";
import "../styles/PageStyles/Contact.css";
import Transition from "../components/Transition";
import Layout from "../components/Layout";
import "../styles/PageStyles/ProjectCategory.css";
import ProminentWorks from "../components/ProminentWorks";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ProjectHeroSection from "../components/ProjectHeroSection";
import { projectCategoryObject } from "../data/projectCategoryData";
import ErrorPage from "./ErrorPage";

const ProjectCat = () => {
  const { projectCategory } = useParams();
  const category = projectCategoryObject?.find(
    (data) => data.type === projectCategory
  );

  if (!category) {
    return <ErrorPage />;
  }

  return (
    <Transition>
      <Layout backgroundColor={category.styles.backgroundColor}>
        <div className="project-category-container" data-scroll-section>
          <Navbar color={category.styles.textColor} />

          <ProjectHeroSection
            categoryText={projectCategory}
            textColor={category.styles.textColor}
          />

          <ProminentWorks
            backgroundColor={category.styles.backgroundColor}
            color={category.styles.textColor}
            prominentWorks={category.data}
          />

          <Footer color={category.styles.textColor} />
        </div>
      </Layout>
    </Transition>
  );
};

export default memo(ProjectCat);
