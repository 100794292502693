import { imageURL } from "./imageUrl";

export const developmentProjectsData = {
  worksLeft: [
    {
      id: "parikshan",
      title: "Parikshan",
      imageSource: imageURL.parikshan.imageHero,
      description:
        "Parikshan is a decentralized application (DApp) built on the blockchain that enables secure and transparent verification of certificates and credentials.",
      linkTo: "/projects/development/parikshan",
      styles: {
        backgroundColor: "var(--soft-peach)",
        textColor: "var(--marble-blue)",
      },
    },
    {
      id: "nirnayaak",
      title: "Nirnayaak",
      imageSource: imageURL.nirnayaak.imagePrimary,
      description:
        "Say goodbye to legal research woes and hello to 'AI-ndian' magic with our document search engine with an innovative ranking algorithm.",
      linkTo: "/projects/development/nirnayaak",
      styles: {
        backgroundColor: "var(--soft-peach)",
        textColor: "var(--marble-blue)",
      },
    },
  ],
  worksRight: [
    {
      id: "parkify",
      title: "Parkify",
      imageSource: imageURL.parkify.imagePrimary,
      description:
        "Parkify is an innovative, AI-powered parking solution that revolutionizes the way we park our vehicles. With its robust and scalable architecture, Parkify offers a cost-effective alternative to traditional parking systems while delivering unparalleled convenience and efficiency.",
      linkTo: "/projects/development/parkify",
      styles: {
        backgroundColor: "var(--soft-peach)",
        textColor: "var(--marble-blue)",
      },
    },
  ],
};

export const brandingProjectsData = {
  worksLeft: [
    {
      id: "dacron",
      title: "dacron",
      imageSource: imageURL.dacron.imagePrimary,
      description:
        "In my role as a designer, I spearheaded the contractual project 'DACRON AI,' crafting a robust design system and developing an AI chat page. Discover how I seamlessly integrated technology and design to create an engaging user experience.",
      linkTo: "/projects/branding/dacron",
      styles: {
        backgroundColor: "var(--old-lace)",
        textColor: "var(--egg-plant)",
      },
    },
    {
      id: "edukit",
      title: "Edukit",
      imageSource: imageURL.edukit.imagePrimary,
      description:
        "For the contractual project EDUKIT, I created a comprehensive design system, developer user console, and a captivating landing page, contributing to a cohesive and user-friendly product.",
      linkTo: "/projects/branding/edukit",
      styles: {
        backgroundColor: "var(--old-lace)",
        textColor: "var(--egg-plant)",
      },
    },
  ],
  worksRight: [
    {
      id: "shringaar",
      title: "Shringaar",
      imageSource: imageURL.shringaar.imagePrimary,
      description:
        "A branding endeavor designed for a small business dedicated to Indian-inspired fashion accessories. Dive into the fusion of culture and elegance brought to life through my creative touch.",
      linkTo: "/projects/branding/shringaar",
      styles: {
        backgroundColor: "var(--old-lace)",
        textColor: "var(--egg-plant)",
      },
    },
    {
      id: "tessarus",
      title: "TESSARUS",
      imageSource: imageURL.tessarus.imagePrimary,
      description:
        "Introducing 'TESSARUS,' a cutting-edge design project developed for our college website. This online ticketing system and event management platform revolutionized the way we organized and experienced 'Espektro' events. ",
      linkTo: "/projects/branding/tessarus",
      styles: {
        backgroundColor: "var(--old-lace)",
        textColor: "var(--egg-plant)",
      },
    },
  ],
};

export const graphicProjectsData = {
  worksLeft: [
    {
      id: "annoyed",
      title: "Annoyed",
      imageSource: imageURL.annoyed.image1,
      description:
        "my graphic design masterpiece that marries the raw beauty of rust with the whimsy of vibrant, adorable boy annoyed. Witness the fusion of contrasting elements in this captivating artwork",
      linkTo: "/projects/graphic/annoyed",
      styles: {
        backgroundColor: "var(--link-water)",
        textColor: "var(--dark-brown)",
      },
    },
    {
      id: "tanjiro",
      title: "Tanjiro",
      imageSource: imageURL.tanjiro.image1,
      description:
        "Meet 'Tanjiro,' my graphic masterpiece inspired by the iconic anime character from Demon Slayer.",
      linkTo: "/projects/graphic/tanjiro",
      styles: {
        backgroundColor: "var(--link-water)",
        textColor: "var(--dark-brown)",
      },
    },
    {
      id: "wellerman",
      title: "Wellerman",
      imageSource: imageURL.wellerman.image1,
      description:
        "Sail alongside a lone figure as they brave the waves, an homage to resilience in the face of life's 'whales' – symbolizing challenges and adversity.",
      linkTo: "/projects/graphic/wellerman",
      styles: {
        backgroundColor: "var(--link-water)",
        textColor: "var(--dark-brown)",
      },
    },
    {
      id: "darkqueen",
      title: "Darkqueen",
      imageSource: imageURL.darkqueen.image1,
      description:
        "Step into the world of 'Darkqueen,' a graphic masterpiece inspired by the enigmatic Queen Bethora, a figure shrouded in history's mysteries. Explore the intricate details that bring her captivating story to life, a blend of darkness and royalty.",
      linkTo: "/projects/graphic/darkqueen",
      styles: {
        backgroundColor: "var(--link-water)",
        textColor: "var(--dark-brown)",
      },
    },
  ],
  worksRight: [
    {
      id: "cutork",
      title: "Cutork",
      imageSource: imageURL.cutork.image1,
      description:
        "An artwork that marries the raw beauty of rust with the whimsy of vibrant, adorable robots. Witness the fusion of contrasting elements in this captivating artwork.",
      linkTo: "/projects/graphic/cutork",
      styles: {
        backgroundColor: "var(--link-water)",
        textColor: "var(--dark-brown)",
      },
    },
    {
      id: "hreinin",
      title: "Hreinin",
      imageSource: imageURL.hreinin.image1,
      description:
        "An artwork depicting a serene mountain backdrop with a poised deer. Bathed in soothing reddish hues, this artwork captures the essence of tranquility and contemplation.",
      linkTo: "/projects/graphic/hreinin",
      styles: {
        backgroundColor: "var(--link-water)",
        textColor: "var(--dark-brown)",
      },
    },
    {
      id: "doodle",
      title: "Doodle",
      imageSource: imageURL.doodle.image1,
      description:
        "'Doodle,' that spring to life, exuding boundless joy and creativity.",
      linkTo: "/projects/graphic/doodle",
      styles: {
        backgroundColor: "var(--link-water)",
        textColor: "var(--dark-brown)",
      },
    },
  ],
};

export const projectCategoryObject = [
  {
    type: "development",
    id: "development",
    data: developmentProjectsData,
    styles: {
      backgroundColor: "var(--soft-peach)",
      textColor: "var(--marble-blue)",
    },
  },
  {
    type: "branding",
    id: "branding",
    data: brandingProjectsData,
    styles: {
      backgroundColor: "var(--old-lace)",
      textColor: "var(--egg-plant)",
    },
  },
  {
    type: "graphic",
    id: "graphic",
    data: graphicProjectsData,
    styles: {
      backgroundColor: "var(--link-water)",
      textColor: "var(--dark-brown)",
    },
  },
];
