import React from "react";

import { Link } from "react-router-dom";
import "../styles/PageStyles/NavPage.css";
import Transition from "../components/Transition";
import Layout from "../components/Layout";
import Navbar from "../components/Navbar";
import TextTransition from "../components/TextTransition";
import useResize from "../hooks/useResize";
import { deviceLayout } from "../utilites/Constant";
import { resumeLink } from "../data/homePageData";

//constants
const navPageTransitionDelay = 0.5;

const NavPage = () => {
  const { screenSize } = useResize();
  return (
    <Transition>
      <Layout>
        <Navbar color="--bg-white" />
        <div className="navpage-container">
          {screenSize.width > deviceLayout.responsivePreview.Mobile[0] && (
            <div class="mesh-background"></div>
          )}
          {screenSize.width > deviceLayout.responsivePreview.Mobile[0] && (
            <div className="navpage-container-left">
              <div>
                <p
                  style={{
                    fontSize:
                      screenSize.width <=
                      deviceLayout.responsivePreview.Tablet[0]
                        ? "var(--text-sub-heading)"
                        : screenSize.width <=
                          deviceLayout.responsivePreview.Mobile[0]
                        ? "var(--text-medium)"
                        : "var(--text-heading)",

                    marginBottom: "0.8rem",
                  }}
                >
                  AMIT SAMUI
                </p>
                <p className="navpage-container-left-sub">
                  MUMBAI, MAHARASHTRA
                </p>
                <p className="navpage-container-left-sub">INDIA</p>
              </div>

              <div>
                <p className="navpage-container-left-sub">+91 7045234921</p>
                <p className="navpage-container-left-sub">
                  amitsamui257@gmail.com
                </p>
              </div>
              <div>
                <p className="navpage-container-left-sub">
                  OPEN FOR CLIENT PROJECTS
                </p>
              </div>
            </div>
          )}

          <div
            className={`navpage-container-right ${
              screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                ? "center-column"
                : "end-column"
            }`}
          >
            <Link className="textlink nav-links" to={"/"}>
              <TextTransition
                text={"HOME"}
                transitionDelay={navPageTransitionDelay}
                size={
                  screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                    ? 3
                    : screenSize.width <=
                      deviceLayout.responsivePreview.Tablet[0]
                    ? 4
                    : 7
                }
              />
            </Link>
            <Link className="textlink nav-links" to={"/projects"}>
              <TextTransition
                text={"PROJECTS"}
                transitionDelay={navPageTransitionDelay + 0.2}
                size={
                  screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                    ? 2.3
                    : screenSize.width <=
                      deviceLayout.responsivePreview.Tablet[0]
                    ? 4
                    : 7
                }
              />
            </Link>
            <Link target="_blank" className="textlink nav-links" to={resumeLink}>
              <TextTransition
                text={"RESUME"}
                transitionDelay={navPageTransitionDelay + 0.4}
                size={
                  screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                    ? 2.6
                    : screenSize.width <=
                      deviceLayout.responsivePreview.Tablet[0]
                    ? 4
                    : 7
                }
              />
            </Link>
            <Link className="textlink nav-links" to={"/contact-page"}>
              <TextTransition
                text={"CONTACT"}
                transitionDelay={navPageTransitionDelay + 0.6}
                size={
                  screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                    ? 2.6
                    : screenSize.width <=
                      deviceLayout.responsivePreview.Tablet[0]
                    ? 4
                    : 7
                }
              />
            </Link>
          </div>
        </div>
      </Layout>
    </Transition>
  );
};

export default NavPage;
