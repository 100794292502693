import { useEffect, useState } from "react";

export const useLoader = (intervalTime) => {
  const [loading, setloading] = useState(true);

  useEffect(() => {
    let timeout;
    if (loading) {
      timeout = setTimeout(() => {
        setloading(false);
      }, intervalTime);
    }

    return () => {};
  }, []);

  return loading;
};
