import React from "react";
import "../styles/ComponentStyles/NavBar.css";
import { Link } from "react-router-dom";

const Navbar = ({ dark = false, color = "var(--bg-white)" }) => {
  return (
    <div className="navbar-container space-between">
      <Link
        className="textlink"
        to="/"
        style={{
          color: color,
        }}
      >
        FD GOD
      </Link>
      <Link className="textlink" to="/nav">
        <div className="navbar-hamburger">
          <div
            style={{
              width: "100%",
              backgroundColor: color,
            }}
            className="hamburger-top hamburger-lines"
          ></div>
          <div
            style={{
              width: "70%",
              backgroundColor: color,
            }}
            className="hamburger-bottom hamburger-lines"
          ></div>
        </div>
      </Link>
    </div>
  );
};

export default Navbar;
