import React, { memo } from "react";
import "../styles/PageStyles/ProjectMain.css";
import { motion } from "framer-motion";
import Layout from "../components/Layout";
import Transition from "../components/Transition";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import TextTransition, {
  TextPushInTransition,
} from "../components/TextTransition";
import Footer from "../components/Footer";
import { useCustomWhileInView } from "../hooks/useCustomWhileInView";
import { fadeInVariant, scaleInLeft } from "../utilites/TransitionVariants";
import ProjectHeroSection from "../components/ProjectHeroSection";
import { projectCategoryData } from "../data/homePageData";
import useResize from "../hooks/useResize";
import { deviceLayout } from "../utilites/Constant";

const ProjectMain = () => {
  const { screenSize } = useResize();

  return (
    <Transition>
      <Layout backgroundColor="var(--bg-green-pastel)">
        <div className="project-container" data-scroll-section>
          <Navbar color="var(--bg-dark)" />
          <ProjectHeroSection categoryText={"PERSONAL"} />

          <div className="project-main-category-section center-column align-end">
            <TextPushInTransition
              text={"PROJECTS CATEGORY"}
              size={
                screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                  ? 1
                  : screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
                  ? 3
                  : 5
              }
              textStyle={{
                color: "var(--bg-dark)",
              }}
              style={{ marginBottom: "2rem" }}
              transitionDelay={0}
            ></TextPushInTransition>

            {projectCategoryData &&
              projectCategoryData?.map((projectCategory, index) => {
                return (
                  <ProjectCategories
                    last={projectCategory.last}
                    title={projectCategory.title}
                    description={projectCategory.description}
                    link={projectCategory.link}
                    key={projectCategory.id}
                    categoryNumber={projectCategory.categoryNumber}
                  />
                );
              })}
          </div>

          <Footer color="var(--bg-dark)" />
        </div>
      </Layout>
    </Transition>
  );
};

const ProjectCategories = ({
  last = false,
  id,
  title,
  description,
  link,
  categoryNumber,
}) => {
  const [ref, controls] = useCustomWhileInView();
  const { screenSize } = useResize();

  return (
    <Link to={link} className="category textlink">
      <div className="category-left center-column">
        <TextTransition
          text={categoryNumber}
          style={{ marginBottom: "2rem" }}
          overlayDivColor="dark"
          size={0.8}
          textStyle={{
            fontWeight: "500",
            fontFamily: "brooklyn",
            letterSpacing: "0",
            color: "var(--bg-dark)",
          }}
          transitionDelay={0}
        />

        <motion.svg
          variants={fadeInVariant}
          initial="hidden"
          animate={controls}
          transition={{ duration: 1, ease: [0.6, 0.01, -0.05, 0.95] }}
          width={
            screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
              ? "30"
              : screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
              ? "20"
              : "50"
          }
          height={
            screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
              ? "30"
              : screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
              ? "20"
              : "50"
          }
          viewBox="0 0 73 73"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 72L72 1M72 1H1M72 1V72"
            stroke="black"
            stroke-width="1"
            stroke-linejoin="round"
          />
        </motion.svg>
      </div>

      <div className="category-type center-column">
        <TextTransition
          text={title}
          style={{ marginBottom: "1rem" }}
          size={
            screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
              ? 0.8
              : screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
              ? 0.6
              : 1
          }
          overlayDivColor="dark"
          textStyle={{
            fontWeight: "700",
            fontFamily: "brooklyn",
            letterSpacing: "0",
            color: "var(--bg-dark)",
          }}
          transitionDelay={0}
        />

        <motion.p
          style={{
            color: "var(--text-dark)",
            textAlign:
              screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                ? "center"
                : screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
                ? "right"
                : "right",
            lineHeight: "1.2rem",
            fontWeight: "700",
          }}
          ref={last ? ref : null}
          variants={fadeInVariant}
          initial="hidden"
          animate={controls}
          transition={{
            duration: 1,
            delay: 0.6,
            ease: [0.6, 0.01, -0.05, 0.95],
          }}
          className="font-small-brooklyn category-description"
        >
          {description}
        </motion.p>
      </div>

      {!last && (
        <motion.span
          ref={ref}
          variants={scaleInLeft}
          initial={"hidden"}
          animate={controls}
          transition={{ duration: 1, ease: [0.6, 0.01, -0.05, 0.95] }}
          className="category-span"
        ></motion.span>
      )}
    </Link>
  );
};

export default memo(ProjectMain);
