import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ContactPage from "./pages/ContactPage";
import NavPage from "./pages/NavPage";
import ProjectMain from "./pages/ProjectMain";
import { AnimatePresence, AnimateSharedLayout } from "framer-motion";
import ProjectCat from "./pages/ProjectCat";
import SingleProject from "./pages/SingleProject";
import ErrorPage from "./pages/ErrorPage";

function App() {
  const location = useLocation();
  return (
    <div className="app">
      <AnimateSharedLayout type="crossfade">
        <AnimatePresence exitBeforeEnter>
          <Routes location={location} key={location.pathname}>
            <Route index element={<HomePage />} />
            <Route path="contact-page" element={<ContactPage />} />
            <Route path="nav" element={<NavPage />} />
            <Route path="projects" element={<ProjectMain />} />
            <Route path="/projects/:projectCategory" element={<ProjectCat />} />
            <Route path="/projects/:projectCategory/:projectId" element={<SingleProject />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </AnimatePresence>
      </AnimateSharedLayout>
    </div>
  );
}

export default App;
