import React, { useEffect, useRef, useState } from "react";
import "../styles/PageStyles/Contact.css";
import Transition from "../components/Transition";
import Layout from "../components/Layout";
import emailjs from "@emailjs/browser";
import Navbar from "../components/Navbar";
import useResize from "../hooks/useResize";
import { deviceLayout } from "../utilites/Constant";

const ContactPage = () => {
  const { screenSize } = useResize();
  const [buttonName, setButtonName] = useState("Send Message");
  const form = useRef();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setButtonName("Send Message");
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [buttonName]);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_NUMBER,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_SECRET_KEY
      )
      .then(
        (result) => {
          setButtonName("Sucessful");
          form.current.reset();
        },
        (error) => {
          setButtonName("Error!!");
        }
      );
  };

  return (
    <Transition>
      <Layout backgroundColor="var(--bg-red-pastel)">
        <Navbar color="var(--bg-dark)" />
        <div className="contact center justify-start ">
          {/* <form ref={form} onSubmit={sendEmail}> */}
          <form
            onSubmit={sendEmail}
            className="form center-column align-start justify-even"
            ref={form}
          >
            <div className="center justify-center input-div">
              <label
                style={{
                  fontSize:
                    screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                      ? "var(--text-normal)"
                      : screenSize.width <=
                        deviceLayout.responsivePreview.Tablet[0]
                      ? "var(--text-medium)"
                      : "var(--text-sub-heading)",
                }}
                className="label"
              >
                Name
              </label>
              <input
                className="input-feild font-normal-brooklyn"
                required
                type="text"
                style={{
                  fontSize:
                    screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                      ? "var(--text-xsmall)"
                      : screenSize.width <=
                        deviceLayout.responsivePreview.Tablet[0]
                      ? "var(--text-small)"
                      : "var(--text-normal)",
                }}
                name="user_name"
              />
            </div>
            <div className="center justify-center input-div">
              <label
                style={{
                  fontSize:
                    screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                      ? "var(--text-normal)"
                      : screenSize.width <=
                        deviceLayout.responsivePreview.Tablet[0]
                      ? "var(--text-medium)"
                      : "var(--text-sub-heading)",
                }}
                className="label"
              >
                Email
              </label>
              <input
                className="input-feild font-normal-brooklyn"
                type="email"
                required
                style={{
                  fontSize:
                    screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                      ? "var(--text-xsmall)"
                      : screenSize.width <=
                        deviceLayout.responsivePreview.Tablet[0]
                      ? "var(--text-small)"
                      : "var(--text-normal)",
                }}
                name="user_email"
              />
            </div>
            <div className="center-column align-start text-div">
              <label
                style={{
                  fontSize:
                    screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                      ? "var(--text-normal)"
                      : screenSize.width <=
                        deviceLayout.responsivePreview.Tablet[0]
                      ? "var(--text-medium)"
                      : "var(--text-sub-heading)",
                }}
                className="label"
              >
                Message
              </label>
              <textarea
              required
                style={{
                  fontSize:
                    screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                      ? "var(--text-xsmall)"
                      : screenSize.width <=
                        deviceLayout.responsivePreview.Tablet[0]
                      ? "var(--text-small)"
                      : "var(--text-normal)",
                }}
                className="text-feild font-normal-brooklyn"
                name="message"
              />
            </div>
            <button className="submit-button" type="submit">
              {buttonName}
            </button>
          </form>
        </div>
      </Layout>
    </Transition>
  );
};

export default ContactPage;
