import React from "react";
import "../../styles/ComponentStyles/singleUseComponent/ContactSection.css";
import { Link } from "react-router-dom";
import useResize from "../../hooks/useResize";
import { deviceLayout } from "../../utilites/Constant";

const ContactSection = () => {
  const { screenSize } = useResize();
  return (
    <div className="contact-section-container center-column">
      <p
        style={{
          fontSize:
            screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
              ? "4rem"
              : screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
              ? "8rem"
              : "13rem",
        }}
        className="contact-big-text"
      >
        DROP
      </p>
      <div className="space-between contact-center">
        <p
          style={{
            fontSize:
              screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                ? "4rem"
                : screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
                ? "8rem"
                : "13rem",
          }}
          className="contact-big-text"
        >
          ME
        </p>
        {screenSize.width > deviceLayout.responsivePreview.Mobile[0] && (
          <p
            style={{
              maxWidth: "40%",
              textAlign: "end",
              lineHeight: "2rem",
              fontSize:
                screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
                  ? "var(--text-small)"
                  : screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                  ? "var(--text-xsmall)"
                  : "var(--text-normal)",
            }}
            className="font-normal-brooklyn"
          >
            IF YOU HAVE ANY IMPROVISATION REGARDING THIS WEBSITE FEEL FREE TO
            REACH ME --ALSO OPEN FOR CREATIVE PROJECTS AND DESIGN
          </p>
        )}
      </div>
      <div className="space-between contact-bottom">
        <p
          style={{
            fontSize:
              screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                ? "4rem"
                : screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
                ? "8rem"
                : "13rem",
          }}
          className="contact-big-text"
        >
          A
        </p>
        <div
          className={`center-column ${
            screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
              ? "align-start width-100"
              : ""
          }`}
        >
          <p
            style={{
              maxWidth:
                screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                  ? "100%"
                  : "80%",
              textAlign:
                screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                  ? "start"
                  : "center",
              lineHeight: "2rem",
              fontSize:
                screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
                  ? "var(--text-small)"
                  : screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                  ? "var(--text-xsmall)"
                  : "var(--text-normal)",
            }}
            className="font-normal-brooklyn"
          >
            Don't be shy to send me a few words - I will get back to you
            DEFINITELY
          </p>
          <Link to={"/contact-page"} className="textlink contact-button center">
            SEND MESSAGE
          </Link>
        </div>
        <p
          style={{
            fontSize:
              screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                ? "4rem"
                : screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
                ? "8rem"
                : "13rem",
            marginTop: "2rem",
          }}
          className="contact-big-text"
        >
          LINE
        </p>
      </div>
    </div>
  );
};

export default ContactSection;
