import React from "react";
//modules
import { Link } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Layout from "../components/Layout";
import Transition from "../components/Transition";
import Navbar from "../components/Navbar";
import Loader from "../components/Loader";
import { useLoader } from "../hooks/useLoader";
import HeroSection from "../components/singleUseComponents/HeroSection";
import WorkExperience from "../components/singleUseComponents/WorkExperience";
import ImageCarousel from "../components/ImageCarousel";
import ProminentWorks from "../components/ProminentWorks";
import ToolsSection from "../components/singleUseComponents/ToolsSection";
import ContactSection from "../components/singleUseComponents/ContactSection";
import Footer from "../components/Footer";
import {
  
  prominentWorksData,
} from "../data/homePageData";

//styles
import "../styles/PageStyles/HomePage.css";
import Achievement from "../components/singleUseComponents/Achievement";



const HomePage = () => {
  const loading = useLoader(5000);

  if (loading) {
    return (
      <AnimatePresence exitBeforeEnter>
        <Loader></Loader>
      </AnimatePresence>
    );
  }

  return (
    <>
      <Transition delay={0.5}>
        <Layout>
          <div className="homepage-container" data-scroll-section>
            <Navbar />
            <HeroSection />
            <WorkExperience />
            <ToolsSection />
            <Achievement />
            <ProminentWorks prominentWorks={prominentWorksData} />

            <div className="homepage-moreprojects center-column ">
              <ImageCarousel dataScrollSpeed="-3" />
              <p className="homepage-moreprojects-text">
                The world infront of you is more than what you see!
              </p>
              <Link
                to={"/projects"}
                className="textlink more-projects-button center"
              >
                See more projects
              </Link>
              <ImageCarousel dataScrollSpeed="3" marqueeDirection="right" />
            </div>
            <ContactSection />
            <Footer />
          </div>
        </Layout>
      </Transition>
    </>
  );
};

export default HomePage;
