import React from "react";
import Transition from "../components/Transition";
import "../styles/PageStyles/ErrorPage.css";
import Layout from "../components/Layout";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const ErrorPage = () => {
  return (
    <Transition>
      <Layout>
        <Navbar color="var(--bg-white)" />
        <div className="error-container center-column">
          <p className="error-404">404</p>
          <p className="error-text">THERE'S NO LIGHT HERE</p>
          <p className="error-sub-text font-normal-brooklyn">
            Seems you have entered wrong page address or the page was removed by
            the owner
          </p>
        </div>
        <Footer color="var(--bg-white)" />
      </Layout>
    </Transition>
  );
};

export default ErrorPage;
