import { motion } from "framer-motion";
import TextTransition from "../TextTransition";
import { Link } from "react-router-dom";
import ArrowSmall from "../ArrowSmall";
import "../../styles/ComponentStyles/singleUseComponent/HeroSection.css";
import { homePageSocialIcons, resumeLink, skillInventory } from "../../data/homePageData";
import Icons from "../Icons";
import useResize from "../../hooks/useResize";
import { deviceLayout } from "../../utilites/Constant";

const homePageTransitionDelay = 0.6;

const HeroSection = () => {
  const { screenSize } = useResize();

  return (
    <div className="hero-section-container center-column">
      <div className="hero-section-container-top">
        {screenSize.width > deviceLayout.responsivePreview.Mobile[0] && (
          <div class="mesh-background-home purple"></div>
        )}
        {screenSize.width > deviceLayout.responsivePreview.Mobile[0] && (
          <div class="mesh-background-home green"></div>
        )}

        <TextTransition
          text={"AMIT SAMUI"}
          transitionDelay={homePageTransitionDelay}
          transitionDuration={1}
          size={
            screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
              ? 1.6
              : screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
              ? 4
              : 7
          }
        />
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{
            duration: 1.2,
            delay: homePageTransitionDelay + 0.2,
            ease: [0.6, 0.01, -0.05, 0.95],
          }}
          className="hero-section-container-top-profile"
        >
          <img style={{ width: "100%" }} src="./Images/profile.webp" />
        </motion.div>
      </div>
      <div className="hero-section-container-bottom">
        {screenSize.width <= deviceLayout.responsivePreview.Mobile[0] && (
          <div className=" hero-section-container-bottom-sub hero-section-container-bottom-mid ">
            <div>
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 1.2,
                  delay: homePageTransitionDelay + 0.4,
                  ease: [0.6, 0.01, -0.05, 0.95],
                }}
                style={{
                  fontSize:
                    screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
                      ? "0.6rem"
                      : screenSize.width <=
                        deviceLayout.responsivePreview.Mobile[0]
                      ? "0.6rem"
                      : "0.8rem",
                }}
                className="hero-section-container-bottom-mid-text"
              >
                Passionate creative designer and skilled software engineer,
                crafting innovative solutions through seamless design and
                impactful code. Let's create together.
              </motion.p>
              <HeroLinks
                textSize={
                  screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
                    ? 0.6
                    : screenSize.width <=
                      deviceLayout.responsivePreview.Mobile[0]
                    ? 0.6
                    : 0.8
                }
                linkName="SEE MY EXPERIENCE"
              />
            </div>
            <Icons
              iconColor={"var(--bg-white)"}
              iconData={homePageSocialIcons}
            />
          </div>
        )}
        <div className="center-columns hero-section-container-bottom-left">
          <TextTransition
            text={"DESIGN + "}
            transitionDelay={homePageTransitionDelay}
            transitionDuration={1}
            size={
              screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
                ? 1.2
                : screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                ? 0.8
                : 2
            }
            style={{
              marginBottom: "0.8rem",
            }}
          />
          <TextTransition
            text={"DEVELOPMENT"}
            transitionDelay={homePageTransitionDelay}
            transitionDuration={1}
            size={
              screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
                ? 1.2
                : screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                ? 0.8
                : 2
            }
            style={{
              marginBottom: "2rem",
            }}
          />

          {skillInventory?.map((skill, index) => {
            return (
              <HeroLinks
                key={skill.skillName}
                linkURL={skill.link}
                textSize={
                  screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
                    ? 0.6
                    : screenSize.width <=
                      deviceLayout.responsivePreview.Mobile[0]
                    ? 0.6
                    : 0.8
                }
                linkName={skill.skillName}
              />
            );
          })}
        </div>
        {screenSize.width > deviceLayout.responsivePreview.Mobile[0] && (
          <div className=" hero-section-container-bottom-sub hero-section-container-bottom-mid ">
            <div>
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 1.2,
                  delay: homePageTransitionDelay + 0.4,
                  ease: [0.6, 0.01, -0.05, 0.95],
                }}
                style={{
                  fontSize:
                    screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
                      ? "0.6rem"
                      : screenSize.width <=
                        deviceLayout.responsivePreview.Mobile[0]
                      ? "0.6rem"
                      : "0.8rem",
                }}
                className="hero-section-container-bottom-mid-text"
              >
                Passionate creative designer and skilled software engineer,
                crafting innovative solutions through seamless design and
                impactful code. Let's create together.
              </motion.p>
              <HeroLinks
                textSize={
                  screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
                    ? 0.6
                    : screenSize.width <=
                      deviceLayout.responsivePreview.Mobile[0]
                    ? 0.6
                    : 0.8
                }linkURL={resumeLink}
                linkName="SEE MY EXPERIENCE"
                targetBlank={true}
              />
            </div>
            <Icons
              iconColor={"var(--bg-white)"}
              iconData={homePageSocialIcons}
            />
          </div>
        )}

        {screenSize.width > deviceLayout.responsivePreview.Mobile[0] && (
          <div className="center-column justify-between hero-section-container-bottom-sub hero-section-container-bottom-right ">
            <div>
              <TextTransition
                text={"12+ DIFFERENT"}
                transitionDelay={homePageTransitionDelay}
                transitionDuration={1}
                size={
                  screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
                    ? 1
                    : screenSize.width <=
                      deviceLayout.responsivePreview.Mobile[0]
                    ? 0.8
                    : 1.5
                }
                style={{
                  marginBottom: "0.8rem",
                }}
              />
              <TextTransition
                text={"WORKS"}
                transitionDelay={homePageTransitionDelay}
                transitionDuration={1}
                size={1.2}
                style={{
                  marginBottom: "2rem",
                }}
              />
              <HeroLinks linkURL="/projects" linkName="SEE MY PROJECTS" />
            </div>
            <div
              style={{
                fontSize:
                  screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
                    ? "0.6rem"
                    : screenSize.width <=
                      deviceLayout.responsivePreview.Mobile[0]
                    ? "0.6rem"
                    : "0.8rem",
              }}
            >
              amitsamui257@gmail.com
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const HeroLinks = ({ linkName, linkURL = "/nav", textSize = 0.8, targetBlank = false }) => {
  return (
    <Link to={linkURL} target={targetBlank ? "_blank" : ""} className="hero-links center textlink">
      <TextTransition
        text={linkName}
        transitionDelay={homePageTransitionDelay}
        transitionDuration={1}
        size={textSize}
        textStyle={{
          fontFamily: "brooklyn",
          letterSpacing: "0",
          fontWeight: 500,
        }}
        style={{
          marginRight: "1.2rem",
        }}
      />
      <ArrowSmall />
    </Link>
  );
};

export default HeroSection;
