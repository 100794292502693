export const imageURL = {
  cutork: {
    image1:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696171555/cutork_l4dkuq.webp",
  },
  hreinin: {
    image1:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696171559/hreinin_fjrv73.webp",
  },
  annoyed: {
    image1:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/c_scale,w_1266/v1696171565/testimage_ruwyb6.webp",
  },
  tanjiro: {
    image1:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696171562/spring_j0oqha.webp",
  },
  wellerman: {
    image1:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/c_scale,w_2000/v1696171561/image_lmcgrh.webp",
  },
  doodle: {
    image1:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/c_scale,w_1200/v1696171558/doodle_jpbdoo.webp",
  },
  darkqueen: {
    image1:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696171556/darkqueen_z3ssiq.webp",
  },

  parikshan: {
    imageHero:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696141012/MacBook_Air_-_1_agt094.webp",
    imagePrimary:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696006005/parikshan_banner_kuw0wx.webp",
    image1:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696140369/candidate_certification_y3hxpp.webp",
    image2:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696140370/upload_xqtm7z.webp",
    image3:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696140370/verify_meooph.webp",
    image4:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/c_scale,w_1000/v1696828699/admin_portal_fs4uer.png",
  },
  parkify: {
    imageHero:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/c_scale,w_3000/v1696255688/Desktop_-_1_ktzhsd.webp",
    imagePrimary:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/c_scale,w_2000/v1696255688/Desktop_-_1_ktzhsd.webp",
    image1:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/c_scale,w_1000/v1696255713/Desktop_-_2_ewuobj.webp",
    image2:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/c_scale,w_1000/v1696264055/Desktop_-_3_d79ew1.webp",
    image3:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/c_scale,w_1000/v1696264055/Desktop_-_4_tv2eyo.webp",
    image4:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696831848/parkify-website_kqfmmq.webp",
  },
  nirnayaak: {
    imageHero:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696264683/41c6157a-c7b0-424a-8403-e1aff5c92959_zni1dy.webp",
    imagePrimary:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696264683/41c6157a-c7b0-424a-8403-e1aff5c92959_zni1dy.webp",
    image1:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696097267/nirnayaak-chat_anib93.webp",
    image2:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696264682/718ddd4e-b282-46b2-8fce-78c1beb7728e_re2acy.webp",
    image3:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696833989/nirnayaakarchitecture_jnxkix.webp",
  },
  dacron: {
    imageHero:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696139865/Slide_4_3_-_1_1_tdqkwp.webp",
    imagePrimary:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696139864/dacronAi-min_1_ed0ory.webp",
    image1:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/c_scale,w_2000/v1696163377/Desktop_-_1-min_zu0sgq.webp",
    image2:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696139864/Chat_site-min_1_t7ovup.webp",
    image3:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696139864/Login-min_1_he1uit.webp",
    image4:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696139864/Desktop_-_2-min_uycscg.webp",
  },
  edukit: {
    imagePrimary:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/c_scale,w_2000/v1696163375/1124282_OPTY431_1_jxozct.webp",
    imageHero:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696163978/9141340_186-min_numdq6.webp",
    image1:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/c_scale,w_2000/v1696163377/Desktop_-_1-min_zu0sgq.webp",
    image2:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/c_scale,w_2000/v1696163376/Desktop_-_2-min_gxa3y4.webp",
  },
  shringaar: {
    imageHero:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696145913/Asset_3_4x-min_he6bb7.webp",
    imagePrimary:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/c_scale,w_1200/v1696145914/Asset_2_4x-min_frbuex.webp",
    image1:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696145913/Asset_4_4x-100-min_gw6eoc.webp",
    image2:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696145913/Asset_5_4x-100-min_mcxpcb.webp",
    image3:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/c_scale,w_2000/v1696145915/Asset_8_4x-min_cm6mmk.webp",
    image4:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/c_scale,w_1200/v1696751597/shringaar_package_f8aytw.webp",
  },
  tessarus: {
    imagePrimary:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/c_scale,w_3000/v1696170666/Galaxy_S21_and_S21_Ultra_Mockups-min_bxfxo4.webp",
    imageHero:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/c_scale,w_3000/v1696170666/Galaxy_S21_and_S21_Ultra_Mockups-min_bxfxo4.webp",
    image1:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696163377/Desktop_-_1-min_zu0sgq.webp",
    image2:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/v1696163376/Desktop_-_2-min_gxa3y4.webp",
    image3:
      "https://res.cloudinary.com/dlavfwfuo/image/upload/c_scale,w_1000/v1696828696/image_vy0rua.png",
  },
};
