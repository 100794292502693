import { imageURL } from "./imageUrl";

export const homePageData = {
  image: imageURL.cutork.image1,
};

export const homePageSocialIcons = {
  github: "https://github.com/AmitSamui",
  linkedin: "https://www.linkedin.com/in/amit-samui-3780631ba/",
  twitter: "https://twitter.com/AmitSamui257",
  instagram: "https://www.instagram.com/fdgod_decipher_it/",
  behance: "https://www.behance.net/amitsamui",
  leetcode: "https://leetcode.com/amit_samui/",
};

export const resumeLink = "https://drive.google.com/file/d/1GMS-TzUoI2fdAMScZabG-SZqvWENwecE/view?usp=sharing";

export const skillInventory = [
  {
    skillName: "CREATIVE IDENTITY AND UI/UX",
    link: "/projects/branding",
  },
  {
    skillName: "WEBSITE DEVELOPMENT",
    link: "/projects/development",
  },
  {
    skillName: "BLOCKCHAIN APPLICAITON DEVELOPMENT",
    link: "/projects/development",
  },
  {
    skillName: "NATIVE APPLICATION DEVELOPMENT",
    link: "/projects/development",
  },
  {
    skillName: "GRAPHIC DESIGNING",
    link: "/projects/graphic",
  },
];

export const experienceAcheivementSection = {
  workExperience: {
    title: "Work Experience",
    sectionDescription: [
      "the chisel shaping",
      "the sculpture of",
      "expertise, refining",
      "skills with every",
      "stroke, carving a",
      "path to mastery.",
    ],
    experience: [
      {
        id: "01",
        experienceName: "STATISTICAL-RESEARCH-INTERN",
        companyName: "NORTH-EASTERN HILL UNIVERSITY",
        experienceDuration: "MAR.23 - CURRENT",
        experienceDescription:
          "Conducted in-depth research on time series forecasting, leveraging statistical models such as ARIMA, SARIMAX, and LSTM algorithms. Developed and implemented data-driven models to analyze and predict trends. Applied Python, Matplotlib , Tensorflow Keras , Pandas and Numpy to extract insights and present findings in a clear and concise manner.",
        experienceLink: "#",
        targetBlank:false
      },
      {
        id: "02",
        experienceName: "FRONT-END-DEVELOPER",
        companyName: "MIND WEBS VENTURE",
        experienceDuration: "FEB.22 - OCT.22",
        experienceDescription:
          "My internship at Mindwebs Ventures was a pivotal learning experience. I contributed to diverse projects, honing UI/UX skills and collaborating effectively, deepening my expertise in frontend development.",
        experienceLink: "https://drive.google.com/file/d/1UXu8MMnW4XMsuWGxCbV52gLrjb9AzrY8/view?usp=sharing",
        targetBlank:true
      },
      {
        id: "03",
        experienceName: "UI/UX LEAD",
        companyName: "GOOGLE DEVELOPER STUDENTS CLUB",
        experienceDuration: "MAR.22 - MAR.23",
        experienceDescription:
          "As the UI/UX Lead at GDSC, I led and managed a dedicated design team, overseeing project completion and ensuring a seamless working of organisation.",
        experienceLink: "https://drive.google.com/file/d/1XVWT9cRop7apdLjYbkcQCHmqg--Gd0u1/view?usp=sharing",
        targetBlank:true
      },
    ],
  },
  achievement: {
    title: "My Achievements",

    singleAchievement: [
      {
        id: "01",
        experienceName: "1ST RUNNER'S UP",
        companyName: "MAJOR LEAGUE HACKING",
        experienceDuration: "FEB.23",
        experienceDescription:
          "As a team, we're thrilled to have secured the first runner-up position at the Major League Hackathon (Diversion), showcasing our tech skills and innovation.",
        experienceLink: "https://devfolio.co/projects/nirnayaak-ec65",
      },
      {
        id: "02",
        experienceName: "1ST RUNNER'S UP",
        companyName: "HACK 4 BENGAL 2.0",
        experienceDuration: "JUL.23",
        experienceDescription:
          "I contributed to the development of Parkify and, as a team, secured the 2nd position in the Hack for Bengal competition, highlighting our collaborative tech achievements.",
        experienceLink: "https://devfolio.co/projects/parkify-22b3",
      },
    ],
  },
};

export const prominentWorksData = {
  worksLeft: [
    {
      id: "parikshan",
      title: "Parikshan",
      imageSource: imageURL.parikshan.image1,
      description:
        "Parikshan is a decentralized application (DApp) built on the blockchain that enables secure and transparent verification of certificates and credentials.",
      linkTo: "/projects/development/parikshan",
      styles: {
        textColor: "var(--text-white)",
      },
    },
    {
      id: "parkify",
      title: "Parkify",
      imageSource: imageURL.parkify.imagePrimary,
      description:
        "Parkify is an innovative, AI-powered parking solution that revolutionizes the way we park our vehicles. With its robust and scalable architecture, Parkify offers a cost-effective alternative to traditional parking systems while delivering unparalleled convenience and efficiency.",
      linkTo: "/projects/development/parkify",
      styles: {
        textColor: "var(--text-white)",
      },
    },
    {
      id: "cutork",
      title: "Cutork",
      imageSource: imageURL.cutork.image1,
      description:
        "An artwork that marries the raw beauty of rust with the whimsy of vibrant, adorable robots. Witness the fusion of contrasting elements in this captivating artwork.",
      linkTo: "/projects/graphic/cutork",
      styles: {
        textColor: "var(--text-white)",
      },
    },
  ],
  worksRight: [
    {
      id: "edukit",
      title: "Edukit",
      imageSource: imageURL.edukit.imagePrimary,
      description:
        "For the contractual project EDUKIT, I created a comprehensive design system, developer user console, and a captivating landing page, contributing to a cohesive and user-friendly product.",
      linkTo: "/projects/branding/edukit",
      styles: {
        textColor: "var(--text-white)",
      },
    },
    {
      id: "shringaar",
      title: "Shringaar",
      imageSource: imageURL.shringaar.imagePrimary,
      description:
        "A branding endeavor designed for a small business dedicated to Indian-inspired fashion accessories. Dive into the fusion of culture and elegance brought to life through my creative touch.",
      linkTo: "/projects/branding/shringaar",
      styles: {
        textColor: "var(--text-white)",
      },
    },
    {
      id: "hreinin",
      title: "Hreinin",
      imageSource: imageURL.hreinin.image1,
      description:
        "An artwork depicting a serene mountain backdrop with a poised deer. Bathed in soothing reddish hues, this artwork captures the essence of tranquility and contemplation..",
      linkTo: "/projects/graphic/hreinin",
      styles: {
        textColor: "var(--text-white)",
      },
    },
  ],
};

export const projectCategoryData = [
  {
    id: "development",
    categoryNumber: "01",
    title: "DEVELOPMENT PROJECTS",
    description:
      "Development involves the process of creating, enhancing, or maintaining software applications, websites, and digital solutions. It encompasses coding, programming, and testing to build functional and user-friendly products.",
    last: false,
    link: "/projects/development",
  },
  {
    id: "branding",
    categoryNumber: "02",
    title: "CREATIVE IDENTITY PROJECTS",
    description:
      "Creative identity and UI/UX design focus on shaping the visual and experiential aspects of digital products. It encompasses creating distinctive brand identities, user interfaces, and user experiences that captivate and engage audiences.",
    last: false,
    link: "/projects/branding",
  },
  {
    id: "graphic",
    categoryNumber: "03",
    title: "GRAPHIC DESIGN PROJECTS",
    description:
      "Graphic designing is the art of crafting visually appealing and communicative designs. It includes creating graphics, illustrations, and layouts for various media, such as print, digital, and marketing materials, to convey messages effectively and aesthetically.",
    last: true,
    link: "/projects/graphic",
  },
];

export const projectCategoryLink = [
  {
    id: "development",
    link: "/projects/development",
    title: "DEVELOPMENT",
  },
  {
    id: "graphic",
    link: "/projects/graphic",
    title: "GRAPHIC",
  },
  {
    id: "branding",
    link: `/projects/branding`,
    title: "BRANDING",
  },
];

export const imageCarousel = [
  {
    id: "cutork",
    image: imageURL.cutork.image1,
  },
  {
    id: "annoyed",
    image: imageURL.annoyed.image1,
  },
  {
    id: "tanjiro",
    image: imageURL.tanjiro.image1,
  },
  {
    id: "wellerman",
    image: imageURL.wellerman.image1,
  },
  {
    id: "doodle",
    image: imageURL.doodle.image1,
  },
  {
    id: "darkqueen",
    image: imageURL.darkqueen.image1,
  },
  {
    id: "hreinin",
    image: imageURL.hreinin.image1,
  },
];
