import React, { memo } from "react";
import "../../styles/ComponentStyles/singleUseComponent/WorkExperience.css";
import { useCustomWhileInView } from "../../hooks/useCustomWhileInView";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import TextTransition from "../TextTransition";
import { fadeInVariant, scaleInLeft } from "../../utilites/TransitionVariants";
import { experienceAcheivementSection } from "../../data/homePageData";
import useResize from "../../hooks/useResize";
import { deviceLayout } from "../../utilites/Constant";

const Achievement = () => {
  const { screenSize } = useResize();

  const workExperience = experienceAcheivementSection.achievement;

  return (
    <div className="work-experience-container center-column">
      <div className="work-experience-top center">
        <div className="work-experience-top-text ">
          <span
            style={{
              fontSize:
                screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                  ? "1.8rem"
                  : screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
                  ? "3rem"
                  : "4rem",
            }}
            className="work-experience-top-text-span"
          >
            {workExperience.title}
          </span>
        </div>
      </div>

      {workExperience.singleAchievement.map((experience, index) => {
        return (
          <SingleAchievement
            key={experience.id}
            id={experience.id}
            experienceName={experience.experienceName}
            companyName={experience.companyName}
            experienceDuration={experience.experienceDuration}
            experienceLink={experience.experienceLink}
            experienceDescription={experience.experienceDescription}
          />
        );
      })}
    </div>
  );
};

const SingleAchievement = ({
  id,
  experienceName,
  experienceDuration,
  experienceDescription,
  experienceLink,
  companyName,
}) => {
  const { screenSize } = useResize();
  const [ref, controls] = useCustomWhileInView();

  return (
    <Link target="_blank" to={experienceLink} className="experience textlink">
      <div
        className={`experience-left ${
          screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
            ? "center justify-between align-center "
            : "center-column"
        }`}
      >
        <TextTransition
          text={id}
          style={{ marginBottom: "2rem" }}
          size={0.8}
          textStyle={{
            fontWeight: "500",
            fontFamily: "brooklyn",
            letterSpacing: "0",
          }}
          transitionDelay={0}
        />

        <motion.svg
          variants={fadeInVariant}
          initial="hidden"
          animate={controls}
          transition={{ duration: 1, ease: [0.6, 0.01, -0.05, 0.95] }}
          width={
            screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
              ? "30"
              : screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
              ? "20"
              : "50"
          }
          height="50"
          viewBox="0 0 73 73"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 72L72 1M72 1H1M72 1V72"
            stroke="white"
            stroke-width="1"
            stroke-linejoin="round"
          />
        </motion.svg>
      </div>
      <div className="experience-role">
        <TextTransition
          text={experienceName}
          style={{ marginBottom: "1rem" }}
          size={
            screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
              ? 0.8
              : screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
              ? 0.6
              : 1
          }
          textStyle={{
            fontWeight: "700",
            fontFamily: "brooklyn",
            letterSpacing: "0",
          }}
          transitionDelay={0}
        />
        <TextTransition
          text={experienceDuration}
          style={{ marginBottom: "1rem" }}
          size={
            screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
              ? 0.8
              : screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
              ? 0.6
              : 0.8
          }
          textStyle={{
            fontWeight: "500",
            fontFamily: "brooklyn",
            letterSpacing: "0",
          }}
          transitionDelay={0}
        />
      </div>
      <div className={`experience-company center-column`}>
        <TextTransition
          text={companyName}
          style={{ marginBottom: "1rem" }}
          size={
            screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
              ? 0.8
              : screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
              ? 0.8
              : 1
          }
          textStyle={{
            fontWeight: "700",
            fontFamily: "brooklyn",
            letterSpacing: "0",
            textAlign: "right",
            lineHeight: 1.5,
          }}
          transitionDelay={0}
        />

        <motion.p
          style={{
            // color: "var(--text-dark",
            textAlign:
              screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                ? "center"
                : screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
                ? "right"
                : "right",
            lineHeight: "1.2rem",
            mixBlendMode: "difference",
          }}
          variants={fadeInVariant}
          initial="hidden"
          animate={controls}
          transition={{
            duration: 1,
            ease: [0.6, 0.01, -0.05, 0.95],
          }}
          className="font-small-brooklyn experience-company-description"
        >
          {experienceDescription}
        </motion.p>
      </div>
      <motion.span
        ref={ref}
        variants={scaleInLeft}
        initial={"hidden"}
        animate={controls}
        transition={{ duration: 1, ease: [0.6, 0.01, -0.05, 0.95] }}
        className="experience-span"
      ></motion.span>
    </Link>
  );
};

export default memo(Achievement);
