import React from "react";
import "../../styles/ComponentStyles/singleUseComponent/ToolsSection.css";
import Marquee from "react-fast-marquee";
import useResize from "../../hooks/useResize";
import { deviceLayout } from "../../utilites/Constant";


const ToolsSection = () => {
  const { screenSize } = useResize();
  return (
    <div className="tool-section-container center-column">
      
      <Marquee
        gradient={screenSize.width <= deviceLayout.responsivePreview.Mobile[0] ? false : true}
        style={{
          mixBlendMode: "difference",
          marginBottom: "1rem",
        }}
        gradientColor={[10, 11, 11]}
      >
        <div
          data-scroll
          data-scroll-direction="horizontal"
          data-scroll-speed="3"
          className="marquee-text "
        >
          {" "}
          REACT JAVASCRIPT FIGMA ILLUSTRATOR HTML{" "}
        </div>
      </Marquee>
      <Marquee
        style={{
          mixBlendMode: "difference",
          marginBottom: "1rem",
        }}
        gradient={screenSize.width <= deviceLayout.responsivePreview.Mobile[0] ? false : true}
        gradientColor={[11, 11, 11]}
      >
        <div
          data-scroll
          data-scroll-direction="horizontal"
          data-scroll-speed="-3"
          className="marquee-text "
        >
          {" "}
          CSS JAVA FRAMER-MOTION REACT-QUERY ANT-DESIGN{" "}
        </div>
      </Marquee>
      <Marquee
        style={{
          mixBlendMode: "difference",
          marginBottom: "1rem",
        }}
        gradient={screenSize.width <= deviceLayout.responsivePreview.Mobile[0] ? false : true}
        gradientColor={[11, 11, 11]}
      >
        <div
          data-scroll
          data-scroll-direction="horizontal"
          data-scroll-speed="3"
          className="marquee-text "
        >
          {" "}
          REDUX SOLIDITY IPFS PHOTOSHOP PROCREATE{" "}
        </div>
      </Marquee>
      <Marquee
        style={{
          mixBlendMode: "difference",
          marginBottom: "1rem",
        }}
        gradient={screenSize.width <= deviceLayout.responsivePreview.Mobile[0] ? false : true}
        gradientColor={[11, 11, 11]}
      >
        <div
          data-scroll
          data-scroll-direction="horizontal"
          data-scroll-speed="-3"
          className="marquee-text "
        >
          {" "}
          TYPESCRIPT REACT-NATIVE D3 GIT GITHUB PYTHON{" "}
        </div>
      </Marquee>
    </div>
  );
};

export default ToolsSection;
