import React, { memo } from "react";
import "../styles/ComponentStyles/Icons.css";
import { BsInstagram, BsGithub, BsBehance,BsLink45Deg, BsLinkedin,BsDribbble, BsBrowserChrome} from "react-icons/bs";
import { FaXTwitter } from "react-icons/fa6";
import { FaDev ,FaFigma } from "react-icons/fa";
import { TbBrandLeetcode } from "react-icons/tb";
import { Link } from "react-router-dom";

const Icons = ({ iconData, iconColor, iconSize = "var(--text-medium)" }) => {
  return (
    <div className="social-icons-container center justify-start">
      {iconData.github && (
        <Link
          target="_blank"
          className="textlink icon-link"
          to={iconData.github}
        >
          <BsGithub
            style={{
              fontSize:  iconSize 
            }}
            color={iconColor}
          />
        </Link>
      )}
      {iconData.linkedin && (
        <Link
          target="_blank"
          className="textlink icon-link"
          to={iconData.linkedin}
        >
          <BsLinkedin
          title="LinkedIN"
            style={{
              fontSize: iconSize,
            }}
            color={iconColor}
          />
        </Link>
      )}
       {iconData.linkTree && (
        <Link
          target="_blank"
          className="textlink icon-link"
          to={iconData.linkTree}
        >
          <BsLink45Deg
          title="LinkedIN"
            style={{
              fontSize: iconSize,
            }}
            color={iconColor}
          />
        </Link>
      )}

      {iconData.instagram && (
        <Link
          target="_blank"
          className="textlink icon-link"
          to={iconData.instagram}
        >
          <BsInstagram
            style={{
              fontSize:  iconSize ,
            }}
            color={iconColor}
          />
        </Link>
      )}

      {iconData.behance && (
        <Link
          target="_blank"
          className="textlink icon-link"
          to={iconData.behance}
        >
          <BsBehance
            style={{
              fontSize: iconSize,
            }}
            color={iconColor}
          />
        </Link>
      )}
       {iconData.dribbble && (
        <Link
          target="_blank"
          className="textlink icon-link"
          to={iconData.dribbble}
        >
          <BsDribbble
            style={{
              fontSize: iconSize,
            }}
            color={iconColor}
          />
        </Link>
      )}
       {iconData.figma && (
        <Link
          target="_blank"
          className="textlink icon-link"
          to={iconData.figma}
        >
          <FaFigma
            style={{
              fontSize: iconSize,
            }}
            color={iconColor}
          />
        </Link>
      )}

      {iconData.twitter && (
        <Link
          target="_blank"
          className="textlink icon-link"
          to={iconData.twitter}
        >
          <FaXTwitter
            style={{
              fontSize: iconSize,
            }}
            color={iconColor}
          />
        </Link>
      )}

      {iconData.devfolio && (
        <Link
          target="_blank"
          className="textlink icon-link"
          to={iconData.devfolio}
        >
          <FaDev
            style={{
              fontSize:  iconSize ,
            }}
            color={iconColor}
          />
        </Link>
      )}
      {iconData.webLink && (
        <Link
          target="_blank"
          className="textlink icon-link"
          to={iconData.webLink}
        >
          <BsBrowserChrome
            style={{
              fontSize: iconSize ,
            }}
            color={iconColor}
          />
        </Link>
      )}
      {iconData.leetcode && (
        <Link
          target="_blank"
          className="textlink icon-link"
          to={iconData.leetcode}
        >
          <TbBrandLeetcode
            style={{
              fontSize: iconSize 
            }}
            color={iconColor}
          />
        </Link>
      )}
    </div>
  );
};

export default memo(Icons);
