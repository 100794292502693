import React from "react";
import "../styles/ComponentStyles/ProjectHeroSection.css";
import { TextPushInTransition } from "./TextTransition";
import { Link } from "react-router-dom";
import { projectCategoryLink } from "../data/homePageData";
import useResize from "../hooks/useResize";
import { deviceLayout } from "../utilites/Constant";

const ProjectHeroSection = ({
  categoryText,
  textColor = "var(--text-dark)",
}) => {
  const { screenSize } = useResize();

  return (
    <div className="project-main-hero-section space-between">
      <div className="project-main-hero-section-left wrap center-columns align-start">
        <TextPushInTransition
          text={
            categoryText === "development" &&
            screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
              ? "dev"
              : categoryText
          }
          size={
            screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
              ? 2
              : screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
              ? 4
              : 6
          }
          transitionDelay={0}
          style={{ marginBottom: "1rem" }}
          textStyle={{ color: textColor }}
        />
        <TextPushInTransition
          text={"WORK"}
          size={
            screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
              ? 2
              : screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
              ? 4
              : 6
          }
          transitionDelay={0.1}
          style={{ marginBottom: "1rem" }}
          textStyle={{ color: textColor }}
        />
        <TextPushInTransition
          text={"SHOWCASE"}
          size={
            screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
              ? 2
              : screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
              ? 4
              : 6
          }
          transitionDelay={0.2}
          style={{ marginBottom: "1rem" }}
          textStyle={{ color: textColor }}
        />
        <TextPushInTransition
          text={"2023"}
          size={
            screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
              ? 2
              : screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
              ? 4
              : 6
          }
          transitionDelay={0.3}
          style={{ marginBottom: "1rem" }}
          textStyle={{ color: textColor }}
        />
      </div>

      {screenSize.width > deviceLayout.responsivePreview.Mobile[0] && (
        <div className="project-main-hero-section-right center-column align-end">
          {projectCategoryLink &&
            projectCategoryLink.map((link, index) => {
              return (
                <Link
                  to={link.link}
                  key={link.id}
                  className="hero-links center textlink"
                >
                  <TextPushInTransition
                    text={link.title}
                    size={0.8}
                    transitionDelay={0.4}
                    style={{ marginBottom: "1.5rem" }}
                    textStyle={{ color: textColor }}
                  />
                </Link>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default ProjectHeroSection;
