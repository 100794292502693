import { imageURL } from "./imageUrl";

export const projectsData = {
  development: {
    parikshan: {
      id: "parikshan",
      title: "Parikshan",
      description:
        "Parikshan is a decentralized application (DApp) built on the blockchain that enables secure and transparent verification of certificates and credentials.",
      linkTo: "/projects/development/parikshan",
      data: {
        heroSection: {
          para: [
            "Make and save your",
            "your Certificate ",
            "Verification and",
            "Certification Easier",
          ],
          title: "PARIKSHAN",
          designNumber: "#APPLICATION 01 ",
          duration: "12/03 - 13/04/23",
          heroImage: imageURL.parikshan.imageHero,
          icons: {
            github: "https://github.com/AmitSamui/parikshan",
          },
        },
        projectDescription: {
          description:
            "Parikshan is a decentralized application (DApp) built on the blockchain that enables secure and transparent verification of certificates and credentials. The system leverages blockchain technology and IPFS (InterPlanetary File System) to ensure the integrity and immutability of certificate records.",
          tools: [
            "React.js",
            "IPFS",
            "Solidity",
            "Truffle",
            "Ganache",
            "Ant Design",
          ],
        },
        projectInformation: [
          {
            title: "Certify the Candidate",
            description:
              "Parikshan offers a robust solution for authorized issuers, allowing them to certify candidates and issue unique certificates seamlessly. This feature empowers institutions and organizations to recognize the accomplishments of individuals who have successfully completed specific courses, exams, or qualifications. With Parikshan, the certification process becomes efficient and secure, ensuring that candidates receive the recognition they deserve.",
            image: imageURL.parikshan.image1,
          },
          {
            title: " certificate protection",
            description:
              "Parikshan utilizes the decentralized IPFS blockchain technology to store certificate files securely. This innovative approach generates unique hashes that provide easy and tamper-proof access to these files. By safeguarding certificates on the blockchain, Parikshan ensures their immutability and protection against unauthorized alterations, preserving the integrity and authenticity of the credentials.",
            image: imageURL.parikshan.image2,
          },
          {
            title: "Add / remove issuer",
            description:
              "Parikshan's flexibility extends to administrators, granting them the ability to effortlessly manage issuers or certificate authorities within the system. Administrators can seamlessly onboard trusted issuers who possess the authority to certify candidates and issue certificates, ensuring that the platform adapts to evolving verification needs and maintains a reliable network of certificate providers.",
            image: imageURL.parikshan.image4,
          },
          {
            title: "Verify Certificate",
            description:
              "Parikshan simplifies the verification of certificates, offering a mechanism that enables third parties, such as employers or educational institutions, to easily authenticate uploaded certificate files. This functionality ensures that certificates are not just stored securely but can also be validated with confidence, promoting trust and transparency in the verification process.",
            image: imageURL.parikshan.image3,
          },

          {
            title: "Future Improvements",
            description:
              "Parikshan's future enhancements encompass QR code integration for smartphone-based certificate verification, a dedicated mobile app for improved accessibility, and potential expansion into secure legal document storage, showcasing its commitment to innovation and adaptability.",
          },
        ],
      },
    },
    parkify: {
      id: "parkify",
      title: "Parkify",
      description:
        "Parikshan is a decentralized application (DApp) built on the blockchain that enables secure and transparent verification of certificates and credentials.",
      linkTo: "/projects/development/parkify",
      data: {
        heroSection: {
          para: [
            "AI-powered parking",
            "solution for",
            "effortless and ",
            "efficient Parking",
          ],
          title: "PARKIFY",
          designNumber: "#APPLICATION 02 ",
          duration: "12/03 - 13/04/23",
          heroImage: imageURL.parkify.imageHero,
          icons: {
            github: "https://github.com/FindMyGarage",
            devfolio: "https://devfolio.co/projects/find-my-garage-bba1",
          },
        },
        projectDescription: {
          description:
            "FindMyGarage formerly known as parkify is a revolutionary AI-powered parking solution that reimagines the parking experience. It offers seamless, efficient, and convenient parking for users while providing parking garage owners with a robust management system. This innovative platform leverages AI algorithms and license plate recognition technology to optimize parking allocation, eliminate manual interventions, and enhance the overall parking experience for all.",
          tools: [
            "React.js",
            "React Native",
            "Mongo db",
            "Express",
            "Node",
            "Tensorflow",
            "Open CV",
          ],
        },
        projectInformation: [
          {
            title: "Why Parkify?",
            description:
              "Parkify presents a compelling choice due to its ability to disrupt traditional parking systems. Its cost-effective and scalable architecture makes it an attractive option for parking garage owners, reducing operational overhead. Users benefit from hassle-free parking with automated allocation, and garage owners can effortlessly manage multiple facilities through a centralized dashboard, ensuring efficiency and cost-effectiveness.",
            image: imageURL.parkify.image1,
          },
          {
            title: "Easy Navigation",
            description:
              "parkify simplifies the process of locating and navigating to a parking space. Users can easily find available parking locations near their destination within the app. With guided navigation, they can navigate directly to their chosen parking facility, saving time and eliminating the stress associated with finding parking spaces in busy areas.",
            image: imageURL.parkify.image2,
          },
          {
            title: "Automated parking",
            description:
              "parkify's standout feature is its automatic license plate detection using AI. This technology eliminates the need for manual registration, making entry and exit swift and efficient. Users can seamlessly enter and exit parking facilities without the need for human intervention, enhancing the overall user experience and reducing wait times.",
            image: imageURL.parkify.image3,
          },
          {
            title: "Parkify Website",
            description:
              "parkify provides a dedicated website for parking garage owners, offering valuable insights and management tools. Owners can access comprehensive analytics, customize pricing rates, and configure available services. This web-based platform streamlines garage management, allowing owners to monitor and manage multiple locations efficiently while gaining crucial insights into utilization and revenue generation.",
            image: imageURL.parkify.image4,
          },
        ],
        projectContributors: [
          {
            name: "Saptarshi Bhattacharya",
            role: "Implemented the logic to read Number Plate text using OpenCV and Custom TensorFlow OCR model.Created Full-Stack MERN Stack Application and entire user flow, and reduced OCR times by 15x",
            icons: {
              github: "https://github.com/sbk2k1",
              linkedin: "https://www.linkedin.com/in/sbk2k1/",
              webLink: "https://www.sbk2k1.tech/",
            },
          },
          {
            name: "Soumyajit Datta",
            role: "Developed Backend and computer vision and object detection system",
            icons: {
              github: "http://github.com/codehackerone",
              linkedin: "https://www.linkedin.com/in/codehackerone/",
              linkTree: "http://linktr.ee/soumyajit.d",
              webLink: "http://soumyajitdatta.in",
            },
          },
          {
            name: "Arya Chakraborty",
            role: "developed computer vision and object detection system",
            icons: {
              github: "https://github.com/AryaChakraborty",
              linkedin:
                "https://www.linkedin.com/in/arya-chakraborty-73a1ba203/",
              webLink: "https://main-portfolio-aryachakraborty.vercel.app/",
              devfolio: "https://devfolio.co/@AryaTito",
            },
          },
          {
            name: "Rupam Mattaber",
            role: "Developed the Front-end website for garage owners.",
            icons: {
              github: "https://github.com/Rupam-Matabber",
              linkedin: "https://www.linkedin.com/in/rupam-matabber",
              twitter: "https://twitter.com/rupam_matabber",
              instagram: "https://www.instagram.com/rupam_matabber?r=nametag",
            },
          },
          {
            name: "Amit Samui",
            role: "Designed and Developed Parkify Mobile application for users. Seamless integration of backend and Google Maps Api for great user experience.",
            icons: {
              github: "https://github.com/AmitSamui",
              linkedin: "https://www.linkedin.com/in/amit-samui-3780631ba/",
              twitter: "https://twitter.com/AmitSamui257",
              instagram: "https://www.instagram.com/fdgod_decipher_it/",
              behance: "https://www.behance.net/amitsamui",
              leetcode: "https://leetcode.com/amit_samui/",
            },
          },
        ],
      },
    },

    nirnayaak: {
      id: "nirnayaak",
      title: "Nirnayaak",
      description:
        "Nirnayaak is a decentralized application (DApp) built on the blockchain that enables secure and transparent verification of certificates and credentials.",
      linkTo: "/projects/development/nirnayaak",
      data: {
        heroSection: {
          para: [
            "welcome to our",
            "document search engine",
            "with an innovative",
            "ranking algorithm.",
          ],
          title: "NIRNAYAAK",
          designNumber: "#APPLICATION 02 ",
          duration: "12/03 - 13/04/23",
          heroImage: imageURL.nirnayaak.imageHero,
          icons: {
            github: "https://github.com/AmitSamui/nirnayaak-frontend",
            devfolio: "https://devfolio.co/projects/nirnayaak-ec65",
            webLink: "https://nirnayaak.co",
          },
        },
        projectDescription: {
          description:
            "Simple and fast retrieval of previously judged cases also provides justice with the help of technological advancements in the field of Artificial Intelligence. A Judge or Lawyer can also upload cases and a common person can use the website for knowing the right legal actions to take in some situations.",
          tools: [
            "React.js",
            "Node.js",
            "Flask",
            "AWS",
            "MongoDB",
            "openAI",
            "twilio",
            "Cohere",
            "Digital Ocean ",
          ],
        },
        projectInformation: [
          {
            title: "Document retrieval",
            description:
              "In the realm of legal research, time is precious. Nirnayaak's advanced keyword search functionality enables swift access to previously adjudicated cases. We understand the frustration of sifting through piles of legal documents, and that's why we simplify the process. Whether you're a seasoned lawyer or a newcomer, our platform ensures that you find the information you need, quickly and efficiently. Say goodbye to tedious and time-consuming searches and hello to precise results that save you both time and effort.",
            image: imageURL.nirnayaak.image2,
          },
          {
            title: "Suggestion Portal",
            description:
              "Nirnayaak offers a unique suggestion portal designed with common citizens in mind. This portal empowers individuals to seek guidance on legal matters effortlessly. Whether you're dealing with a dispute or simply want to understand your legal rights, Nirnayaak welcomes your queries in plain language. Our user-friendly interface ensures that you can navigate the often complex legal landscape with ease. Your questions will receive prompt and reliable responses, bridging the gap between the law and everyday life.",
            image: imageURL.nirnayaak.image1,
          },
          {
            title: "Architecture",
            description:
              "Nirnayaak's architecture is a technological marvel. Powered by cutting-edge tools like OCR, NLP, ML, and AI, it transforms complex legal documents into understandable insights. With Optical Character Recognition (OCR), we convert images into text, followed by spell checks and manual adjustments for clarity. Our system removes common words, standardizes text, and extracts keywords for relevance. Then, using advanced summarization tools, we provide concise document summaries. When you query Nirnayaak, our algorithm ensures fast and precise retrieval, ensuring you get the answers you need.",
            image: imageURL.nirnayaak.image3,
          },
          {
            title: "Know More",
            description:
              "Thank you for considering Nirnayaak as a solution to the challenges in the legal field. If you're interested in learning more about the project, please follow the links provided above. We believe you'll find our platform to be a valuable and innovative tool for legal research, with the potential to make a significant impact on the way legal professionals and the general public access and understand legal information. We hope you enjoy exploring Nirnayaak and experiencing the benefits it has to offer.",
            image: imageURL.nirnayaak.image2,
          },
        ],
        projectContributors: [
          {
            name: "Amit Samui",
            role: "Designed and developed the frontend application for Nirnayaak",
            icons: {
              github: "https://github.com/AmitSamui/nirnayaak-frontend",
              devfolio: "https://devfolio.co/projects/nirnayaak-ec65",
              webLink: "https://nirnayaak.co",
            },
          },
          {
            name: "Soumyajit Datta",
            role: "Managed the team and developed keyword search and ranking system",
            icons: {
              github: "http://github.com/codehackerone",
              linkedin: "http://linkedin.com/codehackerone",
              linkTree: "http://linktr.ee/soumyajit.d",
              webLink: "http://soumyajitdatta.in",
            },
          },
          {
            name: "Arya Chakraborty",
            role: "developed keyword search and ranking system",
            icons: {
              github: "https://github.com/AryaChakraborty",
              linkedin:
                "https://www.linkedin.com/in/arya-chakraborty-73a1ba203/",
              webLink: "https://main-portfolio-aryachakraborty.vercel.app/",
              devfolio: "https://devfolio.co/@AryaTito",
            },
          },
          {
            name: "Anuraag Chakraborty",
            role: "Developed optimised backend for Nirnayaak application.",
            icons: {
              github: "https://github.com/anuragc2001",
              linkedin: "https://www.linkedin.com/in/anuragchakraborty2001",
            },
          },
          {
            name: "Saptarshi Bhattacharya",
            role: "Created pipelines in form of Flask Apps to preprocess pdfs, extract text and keywords from those pdfs. Created scripts to load S3 with PDFs and upload data to mongodb using closed domain searching. Created keywords from the text using manual curation and an unsupervised algorithm called YAKE.",
            icons: {
              github: "https://github.com/sbk2k1",
              linkedin: "https://www.linkedin.com/in/sbk2k1/",
              webLink: "https://www.sbk2k1.tech/",
            },
          },
        ],
      },
    },
  },
  branding: {
    dacron: {
      id: "dacron",
      title: "Dacron",
      description:
        "Parikshan is a decentralized application (DApp) built on the blockchain that enables secure and transparent verification of certificates and credentials.",
      linkTo: "/projects/branding/dacron",
      data: {
        heroSection: {
          para: [
            "Case Study:",
            "Dacron AI - ",
            " Bridging AI and ",
            "Design Excellence",
          ],
          title: "DACRON",
          designNumber: "#UI/UX DESIGN 01 ",
          duration: "12/03 - 13/04/23",
          heroImage: imageURL.dacron.imageHero,
          icons: {
            // behance:
            //   "https://www.behance.net/gallery/180605125/dicron-ai-assistance",
            // dribbble:
            //   "https://dribbble.com/shots/22632506-DICRON-AI-ASSISTANCE",
          },
        },
        projectDescription: {
          description:
            "Dacron AI is a groundbreaking project that exemplifies the seamless integration of cutting-edge conversational AI technology and elegant user interface design. Commissioned by AArish AI, this endeavor aimed to create a platform that not only harnesses the power of AI but also prioritizes user experience through thoughtful design. ",
          tools: ["Designed in FIGMA"],
        },
        projectInformationTtitle: "PROCESS",
        projectInformation: [
          {
            title: "Design System",
            description:
              "The foundation of our design journey was the creation of a comprehensive design system. This system served as the bedrock for maintaining consistency and cohesiveness throughout the project. It encompassed three key elements: Typography,  Color Usage, Component Usage with Variants",
            image: imageURL.dacron.image1,
          },
          {
            title: "Stunning Designs",
            description:
              " The Chat Page UI was at the forefront of our design focus. I strived to create an interface that not only facilitated meaningful interactions but also captivated users. The Login Page UI acted as the gateway to Dacron AI, and I approached its design with the same level of care and attention.",
            image: imageURL.dacron.image2,
          },
          {
            title: "Conclusion",
            description:
              "In summary, Dacron AI represents the successful convergence of AI and design excellence. It showcases how a comprehensive design system, coupled with meticulous attention to detail in Chat Page UI and Login Page UI, can create a holistic user experience. This project is a testament to the endless possibilities when technology and design collaborate to bring a vision to life.",
            image: imageURL.dacron.image3,
          },
          {
            title: "Additional Information",
            description:
              "Dacron AI is a project where I had the privilege of working with AArish AI. This project combines the power of chat GPT technology with user-friendly design. It features an intuitive chat interface, a convenient login/register page, and a cohesive design system. Together, we've created a seamless user experience that integrates advanced AI and elegant design principles.",
            image: imageURL.dacron.image4,
          },
        ],
      },
    },
    edukit: {
      id: "edukit",
      title: "Edukit",
      description:
        "Parikshan is a decentralized application (DApp) built on the blockchain that enables secure and transparent verification of certificates and credentials.",
      linkTo: "/projects/branding/edukit",
      data: {
        heroSection: {
          para: [
            "Edukit: Redefining",
            "education through ",
            "innovation and ",
            "seamless management.",
          ],
          title: "EDUKIT",
          designNumber: "#UI/UX DESIGN 02 ",
          duration: "12/03 - 13/04/23",
          heroImage: imageURL.edukit.imageHero,
          icons: {
            // github: "https://github.com/AmitSamui",
            // linkedin: "https://www.linkedin.com/in/amit-samui-3780631ba/",
            // twitter: "https://twitter.com/AmitSamui257",
            // instagram: "https://www.instagram.com/fdgod_decipher_it/",
            // behance: "https://www.behance.net/amitsamui",
            // leetcode: "https://leetcode.com/amit_samui/",
          },
        },
        projectDescription: {
          description:
            "EDUKIT AI is a transformative online platform that caters to aspiring AI developers. It facilitates efficient learning through the purchase of sample projects. This multifaceted project encompasses a competition page, a company hiring page, discussion forums, and a comprehensive resource page. The design solution delivers scalability and ease of implementation across all these features.",
          tools: ["FIGMA", "DESIGN SYSTEM", "FREEPIK", "PINTREST"],
        },
        projectInformation: [
          {
            title: "Design System",
            description:
              "At the heart of this endeavor, our meticulously crafted design system serves as the bedrock for consistency and cohesiveness. It encompasses thoughtful choices in typography for enhanced content comprehension and a modern, sleek aesthetic. A harmonious color palette complements the chosen typefaces, establishing a visual identity that resonates throughout the platform. The components within this system harmoniously tie together, fostering a unified and visually engaging user experience.",
            image: imageURL.edukit.image1,
          },
          {
            title: "Typography System",
            description:
              "Typography played a vital role in our design, striking a balance between practicality and aesthetics. I carefully chose fonts that ensured text was easy to read, even at smaller sizes, enhancing the overall user experience. Simultaneously, these fonts contributed to the project's modern and sleek appearance, seamlessly fitting into our design system alongside colors and components. This approach resulted in a pleasing and functional visual identity, elevating both the user experience and the project's overall appeal.",
            image: imageURL.edukit.image2,
          },
          {
            title: "Landing Page",
            description:
              "The landing page, often the first point of contact for users, underwent meticulous design to captivate visitors and convey the platform's core features. It effectively communicated EDUKIT AI's unique value proposition, guiding users with intuitive navigation to delve deeper into the platform's offerings.",
            image: imageURL.edukit.imagePrimary,
          },
          {
            title: "Development Console",
            description:
              "The development console design proved pivotal in delivering a seamless user experience. It seamlessly integrated the diverse features of the platform, providing intuitive access to the competition page, company hiring page, discussion forums, and the resource hub. This consolidated approach ensured user convenience, streamlining their journey and interactions within the platform.",
            image: imageURL.edukit.imageHero,
          },
          // {
          //   title: "Know more",
          //   description:
          //     "Feel free to click on the Behance icon above to dive deeper into the project and explore it in more detail. If you're interested in potentially collaborating with me, please don't hesitate to send a message. I'd be delighted to engage in a discussion about the unique value I can bring to your product or project. Let's connect and explore the exciting possibilities together!",
          //   image: imageURL.edukit.imageHero,
          // },
        ],
      },
    },

    shringaar: {
      id: "shringaar",
      title: "Shringaar",
      description:
        "Shringaar, where the journey with elegance unfolds, redefines fashion accessory branding with a blend of Indian and global cultural influences.",
      linkTo: "/projects/branding/dacron",
      data: {
        heroSection: {
          para: ["Shringaar :", "A beautiful ", "journey with", "elegance"],
          title: "SHRINGAAR",
          designNumber: "#BRAND DESIGN 01 ",
          duration: "12/03 - 13/04/23",
          heroImage: imageURL.shringaar.imageHero,
          icons: {
            behance: "https://www.behance.net/gallery/174877409/Shringaar",
          },
        },
        projectDescription: {
          description:
            "Shringaar is a sophisticated fashion accessories brand that weaves elegance into every piece. While deeply rooted in Indian fashion, it also showcases the beauty of cultural accessories from around the world, including Korean and Japanese influences. The brand's essence lies in its ability to infuse elegance into every journey of adornment, making it a standout in the fashion industry.",
          tools: ["ADOBE ILLUSTRATOR", "PHOTOSHOP", "PROCREATE", "FIGMA"],
        },
        projectInformation: [
          {
            title: "Logo Concept",
            description:
              " The Shringaar logo embodies the brand's essence. The letter 'S' forms the core, representing the journey from the starting point to the destination. The graceful curve in the middle symbolizes the elegance that threads through every piece of the brand's collection, evoking a sense of timeless beauty.",
            image: imageURL.shringaar.image1,
          },
          {
            title: "LOGO USAGE",
            description:
              " Shringaar boasts a range of logo marks, each serving a distinct purpose. The primary logo is the full emblem, radiating the brand's essence. The combination logo pairs the emblem with the brand name, ensuring easy recognition. Secondary logos offer flexibility, with variations for different contexts and applications, maintaining the brand's integrity across diverse platforms.",
            image: imageURL.shringaar.image2,
          },
          {
            title: "Business Card",
            description:
              " The Shringaar business card is a vital component of its branding. Beyond contact details, it encapsulates the brand's visual identity, leaving a lasting impression. It serves as a tangible representation of Shringaar's elegance and attention to detail, making it a powerful tool for networking and brand recognition.",
            image: imageURL.shringaar.image3,
          },
          {
            title: "Mockups",
            description:
              "Mockups play a pivotal role in translating brand identity into the real world. Shringaar's mockups vividly illustrate how the brand integrates with daily life, showcasing its products in various settings. These mockups provide a glimpse of the brand's versatility and how it seamlessly fits into the lives of its discerning customers. To know more about the brand, please follow up to the behance link.",
            image: imageURL.shringaar.image4,
          },
        ],
      },
    },
    tessarus: {
      id: "tessarus",
      title: "TESSARUS",
      description:
        "Parikshan is a decentralized application (DApp) built on the blockchain that enables secure and transparent verification of certificates and credentials.",
      linkTo: "/projects/branding/tessarus",
      data: {
        heroSection: {
          para: [
            "TESSARUS: Simplifying",
            "Event Scheduling and",
            "Booking with Modern",
            "UI/UX Design.",
          ],
          title: "TESSARUS",
          designNumber: "#UI/UX DESIGN 03 ",
          duration: "12/03 - 13/04/23",
          heroImage: imageURL.tessarus.imageHero,
          icons: {
            figma:
              "https://www.figma.com/file/ugUD71UWFO34L0gXOQkDyW/Untitled?type=design&node-id=0%3A1&mode=design&t=QKDrfIRjHyOSJ6qo-1",
          },
        },
        projectDescription: {
          description:
            "TESSARUS, an online event management platform, redefines the way events are scheduled and booked with seamless ease. While the application was masterfully developed by GDSC, the UI/UX design, meticulously crafted by me, is characterized by its simplicity and modernity. This case study delves into the design process, highlighting key features and the design system that shaped TESSARUS.",
          tools: ["FIGMA", "DESIGN SYSTEM", "PROCREATE"],
        },
        projectInformation: [
          {
            title: "Design System",
            description:
              "TESSARUS's design system is founded on a minimalist yet engaging approach. It leverages a clean interface with intuitive navigation. A harmonious color palette and typography choices blend to offer a user-friendly experience, ensuring that users can effortlessly explore events and complete bookings.",
            image: imageURL.tessarus.image1,
          },
          {
            title: "UI Features",
            description:
              " The Home Page and admin application serves as a central hub where all events are showcased and added. It provides a quick and comprehensive view, allowing users to filter events based on categories and dates, enhancing discoverability.The Event Booking Page is designed for a seamless booking experience. It features an intuitive interface for users to select event details, specify ticket quantities, and proceed to payment efficiently.The Ticket Retrieval Page streamlines the process of accessing booked tickets. It offers a convenient way for users to retrieve and view their event bookings, minimizing any hassles. Online Payment integration enables secure and hassle-free transactions, offering various payment methods for user convenience. Events categorized by day and type ensure users can easily find events that align with their preferences and schedules. This categorization simplifies event discovery and enhances the overall user experience.",
            image: imageURL.tessarus.image3,
          },
          {
            title: "Know more",
            description:
              " To delve deeper into the TESSARUS, including a closer look at the user interface and features, kindly follow the figma link provided above.",
            // image: imageURL.tessarus.image2,
          },
        ],
        projectContributors: [
          {
            name: "Sudip Maity",
            role: "Developed Front-end application in React.",
            icons: {
              github: "https://github.com/sudip-101",
              linkedin: "https://www.linkedin.com/in/sudip-maiti-51a86b206/",
            },
          },
          {
            name: "Soumyajit Datta",
            role: "Developed Backend Application in NodeJS.",
            icons: {
              github: "http://github.com/codehackerone",
              linkedin: "http://linkedin.com/codehackerone",
              linkTree: "http://linktr.ee/soumyajit.d",
              webLink: "http://soumyajitdatta.in",
            },
          },
          {
            name: "Saptarshi Mandal",
            role: "Developed the Tessarus Admin Application from scratch. The application was the central control system for the Espektro admins for Tech and Non Tech Events.",
            icons: {
              github: "https://github.com/saptarshiweb",
              linkedin: "https://www.linkedin.com/in/saptarshi-mandal-100",
              leetcode: "https://leetcode.com/saptarshi_20/",
            },
          },
          {
            name: "Arya Chakraborty",
            role: "Created analytics routes to retrieve operational analytics and insights",
            icons: {
              github: "https://github.com/AryaChakraborty",
              linkedin:
                "https://www.linkedin.com/in/arya-chakraborty-73a1ba203/",
              webLink: "https://main-portfolio-aryachakraborty.vercel.app/",
              devfolio: "https://devfolio.co/@AryaTito",
            },
          },
        ],
      },
    },
  },
  graphic: {
    cutork: {
      id: "cutork",
      title: "Cutork",
      description:
        "An artwork that marries the raw beauty of rust with the whimsy of vibrant, adorable robots. Witness the fusion of contrasting elements in this captivating artwork.",
      linkTo: "/projects/graphic/cutork",
      data: {
        heroSection: {
          para: [
            "Vibrant 'Cutork': ",
            " Where cute  ",
            "robots play, ",
            " joyfully alive in art.",
          ],
          title: "CUTORK",
          designNumber: "#GRAPHIC 02",
          duration: "12/03 - 13/04/23",
          heroImage: imageURL.cutork.image1,
          icons: {
            behance: "https://www.behance.net/gallery/167167215/robosticker",
          },
        },
        projectDescription: {
          description:
            "Capturing the essence of joy and playfulness, 'Cutork' transports you to a world where adorable robots come to life in vibrant, Procreate-rendered splendor. Inspired by Angella Karikanou's style, this lively piece invites you to witness a charming gathering of robots, each radiating a unique personality as they revel in their whimsical adventures, reminding us that even in a digital age, the magic of imagination knows no bounds.",

          tools: ["Procreate", "noisy brush", "RR sketch flat brush"],
        },
      },
    },
    annoyed: {
      id: "annoyed",
      title: "Annoyed",
      description:
        "Parikshan is a decentralized application (DApp) built on the blockchain that enables secure and transparent verification of certificates and credentials.",
      linkTo: "/projects/development/annoyed",
      data: {
        heroSection: {
          para: [
            "Colorful mishap: ",
            "Annoyed' captures  ",
            "relatable frustration ",
            "beautifully",
          ],
          title: "ANNOYED",
          designNumber: "#GRAPHIC 01 ",
          duration: "12/03 - 13/04/23",
          heroImage: imageURL.annoyed.image1,
          icons: {
            behance: "https://www.behance.net/gallery/167166819/annoyed",
          },
        },
        projectDescription: {
          description:
            "Inspired by Angella Karikanou's style, 'Annoyed' is a vivid and striking artwork that tells a relatable story. In this vibrant piece, a young boy stands exasperated, his colorful world disrupted by an unforeseen mishap—his ice cream has splattered all over him. The dynamic use of colors and bold strokes captures the intensity of the moment, making 'Annoyed' a testament to the power of art to convey emotions and evoke empathy, reminding us all of those moments when life throws an unexpected curveball.",
          tools: ["Procreate", "noisy brush", "RR sketch flat brush"],
        },
      },
    },
    tanjiro: {
      id: "tanjiro",
      title: "Tanjiro",
      imageSource: imageURL.tanjiro.image1,
      description:
        "Parikshan is a decentralized application (DApp) built on the blockchain that enables secure and transparent verification of certificates and credentials.",
      linkTo: "/projects/graphic/tanjiro",
      data: {
        heroSection: {
          para: [
            "Embrace the spirit : ",
            "Tanjiro, the ",
            "descendant of",
            "Hanafuda earring",
          ],
          title: "TANJIRO",
          designNumber: "#GRAPHIC 03 ",
          duration: "12/03 - 13/04/23",
          heroImage: imageURL.tanjiro.image1,
          icons: {
            behance: "https://www.behance.net/gallery/181886619/tanjiro",
          },
        },
        projectDescription: {
          description:
            "Tanjiro is a striking graphic art piece inspired by the beloved character from Demon Slayer. This artwork masterfully captures the essence of Tanjiro's determination and strength, bringing him to life in vivid detail. With meticulous attention to detail, the piece conveys the character's unwavering spirit and courage, making it a visual tribute to one of anime's most iconic heroes. Whether you're a fan of the series or appreciate exceptional artistry, Tanjiro is a captivating work that resonates with both anime enthusiasts and art connoisseurs alike",
          tools: ["Procreate", "noisy brush", "RR sketch flat brush"],
        },
      },
    },
    wellerman: {
      id: "wellerman",
      title: "Wellerman",
      imageSource: imageURL.wellerman.image1,
      description:
        "Sail alongside a lone figure as they brave the waves, an homage to resilience in the face of life's 'whales' – symbolizing challenges and adversity.",
      linkTo: "/projects/graphic/wellerman",
      data: {
        heroSection: {
          para: [
            "A voyage of resilience: ",
            "Wellerman ",
            "celebrates life's ",
            "daring adventures.",
          ],
          title: "WELLERMAN",
          designNumber: "#GRAPHIC 04 ",
          duration: "12/03 - 13/04/23",
          heroImage: imageURL.wellerman.image1,
          icons: {
            behance: "https://www.behance.net/gallery/173445815/wellerman",
          },
        },
        projectDescription: {
          description:
            "Explore 'Wellerman,' a captivating graphic design piece that encapsulates the essence of the classic sea shanty. In this artwork, a lone figure bravely navigates the vast sea, facing the formidable whale, symbolizing the enduring human spirit in the face of life's challenges. 'Wellerman' is a visual journey that pays homage to the resilience, adventure, and camaraderie celebrated in the timeless shanty, inviting viewers to embark on their own epic voyage of imagination and determination.",
          tools: ["Procreate", "noisy brush", "RR sketch flat brush"],
        },
      },
    },
    darkqueen: {
      id: "darkqueen",
      title: "Darkqueen",
      imageSource: imageURL.darkqueen.image1,
      description:
        "Step into the world of 'Darkqueen,' a graphic masterpiece inspired by the enigmatic Queen Bethora, a figure shrouded in history's mysteries. Explore the intricate details that bring her captivating story to life, a blend of darkness and royalty.",
      linkTo: "/projects/graphic/darkqueen",
      data: {
        heroSection: {
          para: [
            "Darkqueen:",
            "History's elegance",
            "meets mysterious ",
            "allure in artistry.",
          ],
          title: "DARKQUEEN",
          designNumber: "#GRAPHIC 05 ",
          duration: "12/03 - 13/04/23",
          heroImage: imageURL.darkqueen.image1,
          icons: {
            behance: "https://www.behance.net/gallery/181886503/dark-queen",
          },
        },
        projectDescription: {
          description:
            "Step into the captivating world of 'Darkqueen,' a graphic masterpiece inspired by the enigmatic Queen Bethora. A figure shrouded in history's mysteries, Queen Bethora was known for her unyielding determination and regal elegance. This artwork pays homage to her legacy, blending darkness and royalty into a mesmerizing portrayal that invites you to explore the intriguing depths of her story, where power and grace converge in a symphony of artistry",
          tools: ["Procreate", "noisy brush", "RR sketch flat brush"],
        },
      },
    },
    hreinin: {
      id: "hreinin",
      title: "Hreinin",
      imageSource: imageURL.hreinin.image1,
      description:
        "An artwork depicting a serene mountain backdrop with a poised deer. Bathed in soothing reddish hues, this artwork captures the essence of tranquility and contemplation.",
      linkTo: "/projects/graphic/hreinin",
      data: {
        heroSection: {
          para: [
            "Hreinin:",
            "Where serenity meets",
            "observation in a",
            "mountain's embrace.",
          ],
          title: "HREININ",
          designNumber: "#GRAPHIC 06",
          duration: "12/03 - 13/04/23",
          heroImage: imageURL.hreinin.image1,
          icons: {
            behance: "https://www.behance.net/gallery/169139133/hreinin",
          },
        },
        projectDescription: {
          description:
            "Behold 'HREININ,' my graphic design masterpiece depicting a serene mountain backdrop with a poised deer. Bathed in soothing reddish hues, this artwork captures the essence of tranquility and contemplation.",
          tools: ["Procreate", "noisy brush", "RR sketch flat brush"],
        },
      },
    },
    doodle: {
      id: "doodle",
      title: "Doodle",
      imageSource: imageURL.doodle.image1,
      description:
        "Parikshan is a decentralized application (DApp) built on the blockchain that enables secure and transparent verification of certificates and credentials.",
      linkTo: "/projects/graphic/doodle",
      data: {
        heroSection: {
          para: [
            "Doodle art : Where ",
            "imagination dances ",
            "freely on the canvas",
            "of spontaneity.",
          ],
          title: "DOODLE",
          designNumber: "#GRAPHIC 07",
          duration: "12/03 - 13/04/23",
          heroImage: imageURL.doodle.image1,
          icons: {
            behance: "https://www.behance.net/gallery/181886439/doodle",
          },
        },
        projectDescription: {
          description:
            "Doodle art is a captivating form of artistic expression that celebrates the spontaneous and unrestrained flow of creativity. It thrives on the simple act of drawing without predefined rules or expectations, allowing the artist's imagination to take center stage. In 'Doodle,' my own artwork, I've harnessed this liberating energy to create a vibrant world where doodles come alive. Each stroke and line represents a burst of inspiration, weaving together a tapestry of playful characters and shapes. It's a testament to the power of creativity to break free from boundaries and explore the uncharted realms of imagination, inviting viewers to embrace their inner child and revel in the joy of spontaneous creation.",
          tools: ["Procreate", "noisy brush", "RR sketch flat brush"],
        },
      },
    },
  },
};

export const projectsDataObject = [
  {
    id: "parikshan",
    projectData: projectsData.development.parikshan,
    navigation: {
      next: "/projects/development/parkify",
      prev: "/projects/development/nirnayaak",
    },
  },
  {
    id: "parkify",
    projectData: projectsData.development.parkify,
    navigation: {
      next: "/projects/development/nirnayaak",
      prev: "/projects/development/parikshan",
    },
  },
  {
    id: "nirnayaak",
    projectData: projectsData.development.nirnayaak,
    navigation: {
      next: "/projects/development/parikshan",
      prev: "/projects/development/parkify",
    },
  },
  {
    id: "dacron",
    projectData: projectsData.branding.dacron,
    navigation: {
      next: "/projects/branding/edukit",
      prev: "/projects/branding/tessarus",
    },
  },
  {
    id: "edukit",
    projectData: projectsData.branding.edukit,
    navigation: {
      next: "/projects/branding/shringaar",
      prev: "/projects/branding/dacron",
    },
  },
  {
    id: "shringaar",
    projectData: projectsData.branding.shringaar,
    navigation: {
      next: "/projects/branding/tessarus",
      prev: "/projects/branding/edukit",
    },
  },
  {
    id: "tessarus",
    projectData: projectsData.branding.tessarus,
    navigation: {
      next: "/projects/branding/dacron",
      prev: "/projects/branding/shringaar",
    },
  },
  {
    id: "annoyed",
    projectData: projectsData.graphic.annoyed,
    navigation: {
      next: "/projects/graphic/tanjiro",
      prev: "/projects/graphic/doodle",
    },
  },
  {
    id: "tanjiro",
    projectData: projectsData.graphic.tanjiro,
    navigation: {
      next: "/projects/graphic/wellerman",
      prev: "/projects/graphic/annoyed",
    },
  },
  {
    id: "wellerman",
    projectData: projectsData.graphic.wellerman,
    navigation: {
      next: "/projects/graphic/darkqueen",
      prev: "/projects/graphic/tanjiro",
    },
  },
  {
    id: "darkqueen",
    projectData: projectsData.graphic.darkqueen,
    navigation: {
      next: "/projects/graphic/cutork",
      prev: "/projects/graphic/wellerman",
    },
  },
  {
    id: "cutork",
    projectData: projectsData.graphic.cutork,
    navigation: {
      next: "/projects/graphic/hreinin",
      prev: "/projects/graphic/darkqueen",
    },
  },
  {
    id: "hreinin",
    projectData: projectsData.graphic.hreinin,
    navigation: {
      next: "/projects/graphic/doodle",
      prev: "/projects/graphic/cutork",
    },
  },
  {
    id: "doodle",
    projectData: projectsData.graphic.doodle,
    navigation: {
      next: "/projects/graphic/annoyed",
      prev: "/projects/graphic/hreinin",
    },
  },
];
