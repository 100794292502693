import React from "react";
import Marquee from "react-fast-marquee";
import "../styles/ComponentStyles/ImageCarousel.css";
import { imageCarousel } from "../data/homePageData";

const ImageCarousel = ({ dataScrollSpeed = "-3" , marqueeDirection = "left" }) => {
  return (
    <div className="image-carousel-container ">
      <div className="image-caraousel-clip-path clip-path-top"></div>
      <Marquee
        gradient={true}
        gradientColor={[18, 18, 18]}
        direction={marqueeDirection}
      >
        <div
          data-scroll
          data-scroll-direction="horizontal"
          data-scroll-speed={dataScrollSpeed}
        >
          {imageCarousel &&
            imageCarousel.map((image, index) => {
              return (
                <img
                alt="artwork"
                  key={`imageCarousel${index}`}
                  src={image.image}
                  className="image-carousel-image"
                ></img>
              );
            })}
        </div>
      </Marquee>
      <div className="image-caraousel-clip-path clip-path-bottom"></div>
    </div>
  );
};

export default ImageCarousel;
