import {  motion } from "framer-motion";
import React from "react";
import "../styles/ComponentStyles/Loader.css";

const Loader = () => {
  return (
    <div className="loader">
      <motion.svg
        width="100"
        height="100"
        viewBox="0 0 140 144"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.path
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{
            delay: 1,
            duration: 12,
            ease: "easeInOut",
            repeat: Infinity,
          }}
          exit={{
            pathLength: 0,
          }}
          d="M50.6574 27.3922L16.9583 2L11.8003 62.7353M50.6574 27.3922L27.1024 50.0392M50.6574 27.3922H64.9279M11.8003 62.7353L17.9899 74.2304M11.8003 62.7353L35.3552 79.3775M11.8003 62.7353L27.1024 50.0392M17.9899 74.2304L2 91.3873M17.9899 74.2304L26.2427 89.8431M2 91.3873L48.0784 119.868M2 91.3873L26.2427 89.8431M48.0784 119.868L62.6928 140.799M48.0784 119.868L63.0367 126.044M48.0784 119.868L26.2427 89.8431M62.6928 140.799L69.5702 142L77.1353 140.799M62.6928 140.799L70.3141 134.021M77.1353 140.799L91.7497 119.868M77.1353 140.799L70.3141 134.021M91.7497 119.868L138 91.3873M91.7497 119.868L77.1353 126.044M91.7497 119.868L114.101 89.8431M138 91.3873L122.526 74.2304M138 91.3873L114.101 89.8431M122.526 74.2304L128.372 62.7353M122.526 74.2304L114.101 89.8431M128.372 62.7353L122.526 2L89.6865 27.3922M128.372 62.7353L105.161 79.3775M128.372 62.7353L112.726 50.0392M89.6865 27.3922L112.726 50.0392M89.6865 27.3922H75.4159M35.3552 79.3775L52.5487 82.9804M35.3552 79.3775L44.4678 90.3578M52.5487 82.9804L54.9558 92.7598M52.5487 82.9804L50.6574 61.3627M54.9558 92.7598L44.4678 90.3578M54.9558 92.7598L63.0367 126.044M44.4678 90.3578L26.2427 89.8431M105.161 79.3775L96.048 90.3578M105.161 79.3775L87.7952 82.9804M96.048 90.3578L85.0442 92.7598M96.048 90.3578L114.101 89.8431M85.0442 92.7598L87.7952 82.9804M85.0442 92.7598L77.1353 126.044M87.7952 82.9804L89.6865 61.3627M63.0367 126.044L70.6018 133.765M63.0367 126.044L70.086 79.3775M70.6018 133.765L77.1353 126.044M70.6018 133.765L70.3141 134.021M77.1353 126.044L70.086 79.3775M27.1024 50.0392L50.6574 61.3627M112.726 50.0392L89.6865 61.3627M89.6865 61.3627L70.086 39.402L50.6574 61.3627M89.6865 61.3627L70.086 79.3775M89.6865 61.3627L75.4159 27.3922M50.6574 61.3627L70.086 79.3775M50.6574 61.3627L64.9279 27.3922M64.9279 27.3922H75.4159"
          stroke="#EEEEEE"
          stroke-width="4"
          strokeDasharray="0 1"
        />
      </motion.svg>
      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 2, ease: [0.22, 1, 0.36, 1], delay: 3 }}
        className="loader-p"
      >
        FD GOD
      </motion.p>
    </div>
  );
};

export default Loader;
