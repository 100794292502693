import React, { useRef } from "react";
import "../styles/ComponentStyles/layout.css";
import {
  LocomotiveScrollProvider,
  useLocomotiveScroll,
} from "react-locomotive-scroll";
import { useLocation } from "react-router-dom";

const Layout = ({ children , backgroundColor = "var(--bg-dark)" }) => {
  
  const containerRef = useRef(null);
  const { scroll } = useLocomotiveScroll();
  const { pathname } = useLocation(); // With react-router

  return (
    <LocomotiveScrollProvider
      options={{
        smooth: true,
        // ... all available Locomotive Scroll instance options
      }}
      watch={
        [
          //...all the dependencies you want to watch to update the scroll EXCEPT the location one
        ]
      }
      location={pathname}
      containerRef={containerRef}
      onLocationChange={(scroll) =>
        scroll.scrollTo(0, { duration: 0, disableLerp: true })
      } // If you want to reset the scroll position to 0 for example
      onUpdate={() => console.log("Updated, but not on location change!")} // Will trigger on
    >
      <div data-scroll-container ref={containerRef} className="layout center" style={{backgroundColor : backgroundColor}}>
        <div className="layout-padding">
          {children}
        </div>
      </div>
    </LocomotiveScrollProvider>
  );
};

export default Layout;
