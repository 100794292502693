import React, { memo } from "react";
import "../styles/PageStyles/SingleProject.css";
import Transition from "../components/Transition";
import Layout from "../components/Layout";
import Navbar from "../components/Navbar";
import { motion } from "framer-motion";
import { useCustomWhileInView } from "../hooks/useCustomWhileInView";
import { TextPushInTransition } from "../components/TextTransition";
import Footer from "../components/Footer";
import ImageReveal from "../components/ImageReveal";
import ArrowSmall from "../components/ArrowSmall";
import { Link, useParams } from "react-router-dom";
import { projectsDataObject } from "../data/projectData";
import useResize from "../hooks/useResize";
import { deviceLayout } from "../utilites/Constant";
import ErrorPage from "./ErrorPage";
import Icons from "../components/Icons";
import { fadeInVariant } from "../utilites/TransitionVariants";

const imageVariant = {
  visible: {
    scale: 1,
    width: "100%",
    height: "70vh",
    translateY: "50%",
    transition: {
      duration: 1.2,
      ease: [0.6, 0.01, -0.05, 0.95],
      delay: 0.6,
    },
  },
  hidden: {
    scale: 1.2,
    width: "300px",
    height: "auto",
  },
};

const SingleProject = ({ textColor = "var(--text-white)" }) => {
  const { projectId } = useParams();

  const data = projectsDataObject.find(
    (project, index) => project.id === projectId
  );

  // if there is no data then show the error page
  if (!data) {
    return <ErrorPage />;
  }

  const { projectData, navigation } = data;

  return (
    <Transition>
      <Layout>
        <div className="single-project-container" data-scroll-section>
          <Navbar />

          <ProjectHeroSection
            heroSectionData={projectData?.data?.heroSection}
          />

          <div className="single-project-content-section center-column">
            <SingleProjectDescription
              projectDescription={projectData?.data?.projectDescription}
            />
            {projectData?.data?.projectInformation !== undefined && (
              <SingleProjectInformation
                projectInformation={projectData?.data?.projectInformation}
              />
            )}

            {projectData?.data?.projectContributors !== undefined && (
              <SingleProjectContributor
                projectContributors={projectData?.data?.projectContributors}
              />
            )}

            <SingleProjectNavigation navigation={navigation} />
          </div>

          {/* </motion.div> */}

          <Footer />
        </div>
      </Layout>
    </Transition>
  );
};

// common sections

const ProjectHeroSection = ({
  textColor = "var(--text-white)",
  imageSource = "cutork.webp",
  heroSectionData,
}) => {
  const { screenSize } = useResize();
  const [ref, controls] = useCustomWhileInView();

  return (
    <div className="single-project-hero-container start-column ">
      <div className="single-project-hero-top center align-start justify-between">
        {screenSize.width > deviceLayout.responsivePreview.Mobile[0] && (
          <div className="single-project-hero-section-left center-columns align-start">
            {heroSectionData?.para?.map((text, index) => {
              return (
                <TextPushInTransition
                  key={`para${index}`}
                  text={text}
                  size={
                    screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                      ? 1
                      : screenSize.width <=
                        deviceLayout.responsivePreview.Tablet[0]
                      ? 1.8
                      : 2.4
                  }
                  transitionDelay={1 + index / 10}
                  style={{ marginBottom: "2rem" }}
                  textStyle={{ color: textColor }}
                />
              );
            })}
          </div>
        )}

        <div className="single-project-hero-section-right center-column align-start space-between">
          <div className="single-project-hero-section-right-top center-column align-start">
            <TextPushInTransition
              text={heroSectionData.designNumber}
              size={0.8}
              transitionDelay={1.8}
              style={{ marginBottom: "1rem" }}
              textStyle={{
                color: textColor,
                fontFamily: "brooklyn",
                fontWeight: "500",
              }}
            />
            <TextPushInTransition
              text={heroSectionData.title}
              size={
                screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                  ? 2
                  : screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
                  ? 1.6
                  : 2
              }
              transitionDelay={2}
              style={{ marginBottom: "1rem" }}
              textStyle={{ color: textColor }}
            />
            <TextPushInTransition
              text={heroSectionData.duration}
              size={0.8}
              transitionDelay={2.2}
              style={{ marginBottom: "1rem" }}
              textStyle={{ color: textColor }}
            />
          </div>
          <motion.div
            initial={"hidden"}
            animate={"visible"}
            transition={{
              duration: 1,
              delay: 2.5,
              ease: [0.6, 0.01, -0.05, 0.95],
            }}
            variants={fadeInVariant}
          >
            <Icons
              iconData={heroSectionData.icons}
              iconColor={"var(--bg-white)"}
            />
          </motion.div>
        </div>
      </div>
      <motion.img
        ref={ref}
        initial="hidden"
        animate={controls}
        viewport={{
          once: true,
        }}
        variants={imageVariant}
        className="image-slide-image"
        src={heroSectionData.heroImage}
        alt="image"
        srcset=""
      />
    </div>
  );
};

const SingleProjectDescription = ({ projectDescription }) => {
  const { screenSize } = useResize();

  return (
    <div className="single-project-description-container">
      <div className="single-project-description-paragraph">
        <p
          style={{
            lineHeight:
              screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                ? "calc(var(--text-small) + 0.8rem)"
                : screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
                ? "calc(var(--text-normal) + 0.8rem)"
                : "calc(var(--text-normal) + 1rem)",

            fontSize:
              screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
                ? "var(--text-small)"
                : screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
                ? "var(--text-normal)"
                : "var(--text-normal)",
          }}
          className="font-normal-brooklyn single-project-description-paragraph-text"
        >
          {projectDescription.description}
        </p>
      </div>
      <div className="single-project-description-tech">
        {projectDescription.tools.map((tool, index) => {
          return (
            <p
              key={`tools${index}`}
              className=" single-project-description-tech-text"
            >
              {tool}
            </p>
          );
        })}
      </div>
      <div></div>
    </div>
  );
};

const SingleProjectInformation = ({ projectInformation }) => {
  const { screenSize } = useResize();
  

  return (
    <div className="single-project-information-container ">
      <TextPushInTransition
        text={"FEATURES"}
        size={
          screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
            ? 2
            : screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
            ? 3
            : 5
        }
        textStyle={{
          color: "var(--bg-white)",
        }}
        style={{ marginBottom: "4rem" }}
        transitionDelay={0}
      ></TextPushInTransition>
      {projectInformation.map((information, index) => {
        if ((index & 1) === 0) {
          return (
            <div className="single-project-information-card wrap center align-start  justify-between">
              <div className="single-project-information-card-left start-column align-start justify-start">
                <TextPushInTransition
                  text={information.title}
                  size={
                    screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
                      ? 1
                      : screenSize.width <=
                        deviceLayout.responsivePreview.Mobile[0]
                      ? 1
                      : 2
                  }
                  textStyle={{
                    color: "var(--bg-white)",
                  }}
                  style={{ marginBottom: "2rem" }}
                  transitionDelay={0.6}
                ></TextPushInTransition>
                <p
                  style={{
                    color: "var(--text-white)",
                    textAlign: "start",
                    lineHeight:
                      screenSize.width <=
                      deviceLayout.responsivePreview.Tablet[0]
                        ? "calc(var(--text-small) + 0.8rem)"
                        : screenSize.width <=
                          deviceLayout.responsivePreview.Mobile[0]
                        ? "calc(var(--text-xsmall) + 0.8rem)"
                        : "calc(var(--text-normal) + 1rem)",
                    fontWeight: "500",
                    mixBlendMode: "difference",
                    fontSize:
                      screenSize.width <=
                      deviceLayout.responsivePreview.Tablet[0]
                        ? "var(--text-small)"
                        : screenSize.width <=
                          deviceLayout.responsivePreview.Mobile[0]
                        ? "var(--text-xsmall)"
                        : "var(--text-normal)",
                  }}
                  className="font-normal-brooklyn"
                >
                  {information.description}
                </p>
              </div>
              {information.image && (
                <div className="single-project-information-card-right">
                  <ImageReveal
                    width="100%"
                    height="400px"
                    imageSource={information.image}
                  />
                </div>
              )}
            </div>
          );
        } else if ((index & 1) === 1) {
          return (
            <div className="single-project-information-card wrap direction-row-reverse center align-start justify-between">
              <div className="single-project-information-card-left end-column align-end justify-end">
                <TextPushInTransition
                  text={information.title}
                  size={
                    screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
                      ? 1
                      : screenSize.width <=
                        deviceLayout.responsivePreview.Mobile[0]
                      ? 1
                      : 2
                  }
                  textStyle={{
                    color: "var(--bg-white)",
                  }}
                  style={{ marginBottom: "2rem" }}
                  transitionDelay={0.6}
                ></TextPushInTransition>
                <p
                  style={{
                    color: "var(--text-white)",
                    textAlign: "end",
                    lineHeight:
                      screenSize.width <=
                      deviceLayout.responsivePreview.Tablet[0]
                        ? "calc(var(--text-small) + 0.8rem)"
                        : screenSize.width <=
                          deviceLayout.responsivePreview.Mobile[0]
                        ? "calc(var(--text-xsmall) + 0.8rem)"
                        : "calc(var(--text-normal) + 1rem)",
                    fontWeight: "500",
                    mixBlendMode: "difference",
                    fontSize:
                      screenSize.width <=
                      deviceLayout.responsivePreview.Tablet[0]
                        ? "var(--text-small)"
                        : screenSize.width <=
                          deviceLayout.responsivePreview.Mobile[0]
                        ? "var(--text-xsmall)"
                        : "var(--text-normal)",
                  }}
                  className="font-normal-brooklyn"
                >
                  {information.description}
                </p>
              </div>
              {information.image && (
                <div className="single-project-information-card-right">
                  <ImageReveal
                    width={
                      screenSize.width <=
                      deviceLayout.responsivePreview.Mobile[0]
                        ? "100%"
                        : "auto"
                    }
                    height={
                      screenSize.width <=
                      deviceLayout.responsivePreview.Mobile[0]
                        ? "400px"
                        : "100%"
                    }
                    imageSource={information.image}
                  />
                </div>
              )}
            </div>
          );
        }
      })}
    </div>
  );
};

const SingleProjectContributor = ({ projectContributors }) => {
  const { screenSize } = useResize();
  return (
    <div className="single-project-contributor-container single-project-sections">
      <TextPushInTransition
        text={"CONTRIBUTORS"}
        size={
          screenSize.width <= deviceLayout.responsivePreview.Mobile[0]
            ? 1.5
            : screenSize.width <= deviceLayout.responsivePreview.Tablet[0]
            ? 3
            : 5
        }
        textStyle={{
          color: "var(--bg-white)",
        }}
        style={{ marginBottom: "4rem" }}
        transitionDelay={0}
      ></TextPushInTransition>
      <div className="single-project-contributor-list">
        {projectContributors.map((contributor, index) => {
          return (
            <div className="single-project-contributor-list-item ">
              <div className="single-project-contributor-list-item-left">
                <p
                  style={{
                    fontSize: "var(--text-normal)",
                    marginBottom: "2rem",
                  }}
                >
                  {contributor.name}
                </p>
                <Icons
                  iconSize="var(--text-small)"
                  iconData={contributor?.icons}
                />
              </div>
              <div className="single-project-contributor-list-item-right">
                <p
                  style={{
                    fontSize: "var(--text-normal)",
                    marginBottom: "1rem",
                  }}
                >
                  Role
                </p>
                <p
                  style={{
                    lineHeight: "calc(var(--text-small) + 0.8rem)",
                    fontSize:
                      screenSize.width <=
                      deviceLayout.responsivePreview.Tablet[0]
                        ? "var(--text-small)"
                        : screenSize.width <=
                          deviceLayout.responsivePreview.Mobile[0]
                        ? "var(--text-xsmall)"
                        : "var(--text-normal)",
                  }}
                  className="font-small-brooklyn single-project-contributor-list-item-role-desc"
                >
                  {contributor.role}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

// different sections

const SingleProjectTestimonials = () => {
  return (
    <div className="single-project-testimonials-container single-project-sections">
      SingleProjectTestimonials
    </div>
  );
};

const SingleProjectAdditionalInformation = () => {
  return (
    <div className="single-project-additional-container single-project-sections">
      SingleProjectAdditionalInformation
    </div>
  );
};

const SingleProjectNavigation = ({ navigation }) => {
  return (
    <div className="single-project-navigation-container center justify-between">
      <Link
        to={navigation.prev}
        className="single-project-navigation-previous textlink single-project-navigation-link center"
      >
        <div className="arrow-svg">
          <ArrowSmall width="30" height="30" />
        </div>
        <p className="single-project-navigation-text">Previous</p>
      </Link>

      <Link
        to={navigation.next}
        className="single-project-navigation-next center textlink"
      >
        <p className="single-project-navigation-text">Next</p>
        <ArrowSmall width="30" height="30" />
      </Link>
    </div>
  );
};

export default memo(SingleProject);
